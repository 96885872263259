:root {
	--primary-red: #7e2a3d;
	--white: white;
	--creme-white: #faf8f5;
	--light-gold: #d8d2bc;
	--black: black;
	--secondary-gold: #b3aa7e;
	--ghost-white: #f5f6fa;
}

h2 {
	color: var(--primary-red);
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 32px;
	font-weight: 700;
	line-height: 36px;
}

.content-container-2 {
	z-index: 2;
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	flex-flow: column;
	width: 100%;
	max-width: 1350px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	position: relative;
}

.herovideosection {
	height: 100%;
}

.hero-message {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-webkit-backdrop-filter: brightness(120%) contrast(130%) blur(9px);
	backdrop-filter: brightness(120%) contrast(130%) blur(9px);
	background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45));
	border-radius: 0;
	flex-flow: column;
	justify-content: flex-end;
	align-items: flex-start;
	width: 25vw;
	height: 100%;
	margin-left: auto;
	margin-right: 5%;
	padding-bottom: 64px;
	padding-left: 50px;
	padding-right: 64px;
	display: flex;
	position: relative;
	top: 0;
	bottom: 0;
}

.hero-heading {
	color: var(--white);
	max-width: 400px;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 3vw;
	font-weight: 400;
	line-height: 1.1em;
}

.body {
	background-color: var(--creme-white);
	color: #000;
	background-image: url('/20181016082423/assets/images/gradient-gold---20opacity-reflected.svg');
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: auto;
	background-attachment: fixed;
	font-family: Outfit, sans-serif;
}

.paragraph {
	max-width: 350px;
	margin-bottom: 0;
}

.background-video {
	height: 100%;
	padding-top: 100px;
	display: flex;
}

.input-under-hero-section {
	z-index: 5;
	background-color: var(--white);
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 25%;
	padding-top: 16px;
	padding-bottom: 16px;
	display: flex;
	position: relative;
}

.cta-buttons {
	background-color: var(--white);
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 25%;
	min-width: 25%;
	margin-left: auto;
	margin-right: 5%;
	padding-top: 16px;
	padding-bottom: 16px;
	display: flex;
}

.tabs1_link-service {
	color: #09569b;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.8);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 175px;
	height: 100%;
	padding: 1.05rem 2.5rem;
	font-size: 1.25rem;
	font-weight: 600;
	transition: all 0.2s;
	display: flex;
}

.tabs1_link-service:hover {
	background-color: #fff;
}

.tabs1_link-service.w--current {
	color: #fff;
	background-color: #09569b;
}

.tabs1_link-service.horizontal {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	background-color: var(--light-gold);
	color: var(--black);
	cursor: pointer;
	border-radius: 10px;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	max-width: 200px;
	height: 70px;
	padding: 0.7rem 0.7rem 0.7rem 1rem;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.tabs1_link-service.horizontal:hover {
	background-color: var(--primary-red);
	color: #fff;
}

.iconwrap {
	width: 50px;
	max-width: 50%;
	height: 50px;
	margin-bottom: 5px;
}

.iconwrap.horizontal {
	align-self: center;
	width: 25px;
	height: 25px;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
}

.iconwrap.horizontal.small {
	width: 25px;
	height: 25px;
}

.html-embed {
	width: 100%;
	height: 100%;
}

.html-embed.alternative {
	justify-content: center;
	align-items: center;
	width: auto;
	display: flex;
}

.quick-links {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	align-content: stretch;
	justify-content: stretch;
	width: 25vw;
	height: 100%;
	margin-left: auto;
	margin-right: 5%;
	display: grid;
}

.textwithicon {
	text-align: left;
	width: 100px;
	margin-left: 5px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 18px;
}

.hero-section {
	z-index: 999;
	background-image: linear-gradient(rgba(0, 0, 0, 0.48), rgba(255, 255, 255, 0) 41%);
	flex-flow: column;
	justify-content: flex-start;
	align-items: stretch;
	height: 80vh;
	display: flex;
	position: relative;
}

.form-container {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
	display: flex;
}

.text-block-45 {
	font-size: 16px;
	line-height: 23px;
}

.field-container {
	z-index: 1;
	background-color: var(--light-gold);
	color: var(--black);
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	padding: 10px 12px 10px 32px;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: flex;
}

.field-container:hover {
	background-color: var(--light-gold);
}

.form-2 {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: space-between;
	display: flex;
}

.dropdown-list {
	background-color: rgba(255, 255, 255, 0);
	overflow: auto;
}

.dropdown-list.w--open {
	z-index: 1500;
	background-color: rgba(255, 255, 255, 0);
	border-radius: 10px;
	max-height: 300px;
	margin-top: 10px;
	top: 48px;
	box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2);
}

.dropdown-list.custom-scrollbar.w--open {
	z-index: 1500;
	border: 1px solid var(--white);
	overflow: auto;
	box-shadow: 5px 10px 13px -16px rgba(0, 0, 0, 0.2);
}

.dropdown-link-2 {
	color: var(--black);
	border-radius: 5px;
}

.dropdown-link-2:hover {
	background-color: var(--light-gold);
}

.icon-9 {
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
	position: static;
}

.btn {
	color: #fff;
	text-align: center;
	text-transform: capitalize;
	background-color: #09569b;
	border-radius: 100px;
	justify-content: center;
	align-self: flex-start;
	align-items: center;
	min-width: 175px;
	padding: 10px 6px;
	font-family: Lato, sans-serif;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
	transition-duration: 0.5s;
	display: inline-block;
	position: relative;
	overflow: hidden;
}

.btn:hover {
	background-color: #e9550d;
}

.dropdown-nav-list {
	background-color: var(--creme-white);
	color: #09569b;
	max-height: 250px;
	padding: 8px;
	font-size: 16px;
	overflow: auto;
}

.dropdown-nav-list.w--open {
	color: var(--black);
	background-color: rgba(255, 255, 255, 0);
	min-width: 155px;
	padding: 6px 0 0;
	overflow: visible;
	box-shadow: 4px 10px 9px -9px rgba(0, 0, 0, 0.2);
}

.dropdown-nav-list.custom-scrollbar {
	overflow: scroll;
}

.page-h3 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 30px;
	font-weight: 400;
	line-height: 1.2em;
}

.doctor-search-tool {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	margin-bottom: 0;
	display: flex;
}

.newssection {
	grid-column-gap: 64px;
	grid-row-gap: 64px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	padding: 6rem 5% 100px;
	display: flex;
}

.advant__block {
	background-color: var(--light-gold);
	box-shadow: none;
	border-radius: 8px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	padding: 32px 32px 16px;
	transition: all 0.3s;
	display: flex;
}

.advant__block:hover {
	background-color: #fff;
	transform: translate(0, -20px);
	box-shadow: 0 100px 80px -60px rgba(9, 13, 28, 0.06);
}

.advant__block.locations {
	cursor: pointer;
	padding: 16px;
}

.advant__block.locations:hover {
	background-color: var(--secondary-gold);
}

.advant__col {
	flex: 1;
}

.advant__img-grad {
	z-index: 0;
	background-color: var(--primary-red);
	color: var(--white);
	border-radius: 6px 14px 6px 24px;
	height: 0%;
	transition: all 0.3s;
	position: absolute;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.advant__columns {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	align-items: stretch;
	display: flex;
}

.advant__columns.locations {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
}

.advant__img-wrap {
	z-index: 1;
	background-color: #fff;
	border-radius: 6px 14px 6px 24px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
	padding: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
	box-shadow: 0 10px 30px rgba(0, 90, 141, 0.05);
}

.advant__heading {
	height: 45px;
	margin-top: 16px;
}

.p {
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5em;
}

.newsdate {
	z-index: 1;
	margin-bottom: 0;
	font-size: 15px;
	position: relative;
}

.page-h2 {
	color: var(--primary-red);
	text-align: center;
	text-transform: capitalize;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 45px;
	font-weight: 400;
	line-height: 1.2em;
}

.specialtiesservicessection {
	grid-column-gap: 64px;
	grid-row-gap: 64px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	padding-left: 5%;
	padding-right: 5%;
	display: flex;
}

.tabs {
	flex-flow: row;
	align-items: center;
	display: flex;
}

.tabs-menu {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-flow: column;
	width: 20%;
	height: 100%;
	display: flex;
}

.tabs-content {
	width: 80%;
	height: 100%;
	padding-left: 32px;
	padding-right: 32px;
}

.tab {
	background-color: var(--white);
	border-radius: 5px;
	padding-top: 16px;
	padding-bottom: 16px;
	font-size: 16px;
}

.tab:hover {
	background-color: var(--light-gold);
}

.tab.w--current {
	background-color: var(--primary-red);
	color: var(--white);
}

.tab.out {
	display: none;
}

.tabpane {
	width: 100%;
	height: 300px;
}

.u-img-cover {
	object-fit: cover;
	border-radius: 10px;
	width: 45%;
	height: 100%;
}

.txtdiv {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 55%;
	display: flex;
}

.pane-content {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	justify-content: flex-start;
	align-items: stretch;
	width: 100%;
	height: 100%;
	display: flex;
}

.regular-paragraph {
	max-width: 700px;
	margin-bottom: 0;
	font-size: 16px;
	line-height: 23px;
}

.primary-btn {
	z-index: 1;
	background-color: var(--light-gold);
	color: var(--black);
	text-align: center;
	text-transform: capitalize;
	white-space: normal;
	word-break: normal;
	border-radius: 50px;
	min-width: 150px;
	margin-left: 0;
	margin-right: 0;
	padding: 16px;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	position: relative;
	overflow: hidden;
}

.primary-btn:hover {
	background-color: var(--secondary-gold);
}

.primary-btn.red {
	background-color: var(--primary-red);
	color: var(--white);
	white-space: nowrap;
	border-radius: 50px;
	min-width: auto;
	padding-top: 11px;
	line-height: 16px;
	overflow: visible;
}

.primary-btn.red:hover {
	background-color: #460010;
}

.primary-btn.red.alt {
	padding-left: 20px;
	padding-right: 20px;
}

.primary-btn.red._150 {
	min-width: 150px;
}

.primary-btn.alt {
	border: 1px solid var(--light-gold);
	padding: 10px 0;
	font-size: 15px;
	overflow: visible;
}

.secondary {
	color: var(--primary-red);
	align-items: center;
	min-height: 50px;
	margin-left: -10px;
	font-weight: 700;
	text-decoration: none;
	transition: color 0.2s;
	display: flex;
	position: relative;
}

.pinpoint-inner-content .secondary {
	color: var(--primary-red) !important;
	align-items: center !important;
	min-height: 50px !important;
	margin-left: -10px !important;
	font-weight: 700 !important;
	text-decoration: none !important;
	transition: color 0.2s !important;
	display: flex !important;
	position: relative !important;
	min-width: auto !important;
	background-color: transparent !important;
	padding: 0px !important;
}

.pinpoint-inner-content .secondary:hover {
	color: var(--primary-red) !important;
	background-color: transparent !important;
}

.pinpoint-inner-content .secondary .button-text {
	font-family: Outfit, sans-serif;
}

.button-background {
	border: 2px solid var(--primary-red);
	border-radius: 25px;
	width: 50px;
	height: 50px;
	position: absolute;
}

.button-text {
	z-index: 1;
	margin-left: 16px;
	margin-right: 10px;
	font-size: 16px;
	position: relative;
}

.button-arrow {
	width: 20px;
	margin-right: 20px;
}

.buttonwrapper {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1350px;
	display: flex;
}

.dividerline {
	background-color: var(--primary-red);
	border-radius: 20px;
	width: 90%;
	height: 1px;
}

.clinicssection {
	grid-column-gap: 64px;
	grid-row-gap: 64px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	padding: 100px 5%;
	display: flex;
}

.imagewrapper {
	z-index: 1;
	background-color: #fff;
	border-radius: 6px 14px 6px 24px;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 200px;
	margin-bottom: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
	box-shadow: 0 10px 30px rgba(0, 90, 141, 0.05);
}

.imagewrapper._1 {
	background-image: url('/20181016082423/assets/images/ames.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.imagewrapper._2 {
	background-image: url('/20181016082423/assets/images/Belmond_Campus_620141_74D76C9035F18.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.imagewrapper._3 {
	background-image: url('/20181016082423/assets/images/boone.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.imagewrapper._4 {
	background-image: url('/20181016082423/assets/images/ames.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.arrow {
	background-color: var(--light-gold);
	background-image: url('/20181016082423/assets/images/arrow-narrow-right-svgrepo-com.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 50px;
	align-self: center;
	width: 50px;
	height: 50px;
}

.arrow:hover {
	background-color: var(--secondary-gold);
}

.arrow.left {
	transform: rotate(180deg);
}

.link {
	color: var(--black);
	text-decoration: underline;
}

.link:hover {
	color: var(--primary-red);
	text-decoration: none;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.footer-text-link {
	color: #fff;
	border-bottom-style: solid;
	border-bottom-color: rgba(255, 255, 255, 0.25);
	font-weight: 400;
}

.link.footer-text-link:hover {
	color: #d8d2bc;
	border-bottom-color: rgba(216, 210, 188, 0.4);
}

.link.footer-text-link.w--current {
	color: #d8d2bc;
}

.link.footer-text-link.view-map {
	color: var(--black);
	background-image: url('/20181016082423/assets/images/icon_arrow-26.svg');
	background-position: 0 5px;
	background-repeat: no-repeat;
	background-size: 7px 7px;
	padding-right: 2px;
}

.link.footer-text-link.view-map:hover {
	color: var(--primary-red);
}

.link.white {
	color: var(--white);
}

.link.white:hover {
	color: var(--black);
}

.footer-column-title {
	color: var(--primary-red);
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 0.125em;
	font-size: 1.1875rem;
	font-weight: 400;
	line-height: 1.25em;
}

.footer-container {
	max-width: 1350px;
}

.footer-address-column {
	flex-flow: column;
	justify-content: space-between;
	width: 49%;
	line-height: 1.75em;
	display: flex;
}

.footer-links-list-item {
	color: var(--black);
	width: 100%;
	margin-bottom: 0.25em;
	display: inline-block;
}

.footer-links-list {
	width: 40%;
	margin-bottom: 0;
}

.footer-content-row {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: space-between;
	display: flex;
}

.footer-content-wrap {
	background-color: var(--white);
	padding: 2rem 32px 2.5rem;
	transition: all 0.2s;
	box-shadow: 0 -6px #b2a68b;
}

.social-icons-wrap {
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.footer-list-link {
	color: var(--black);
	background-image: url('/20181016082423/assets/images/icon_arrow-26.svg');
	background-position: 0 7px;
	background-repeat: no-repeat;
	background-size: 7px 7px;
	padding-left: 0;
	padding-right: 5px;
	font-size: 16px;
	display: inline-block;
}

.footer-list-link:hover {
	color: var(--primary-red);
}

.footer-list-link.w--current {
	color: #d8d2bc;
	letter-spacing: 0.03em;
	font-weight: 500;
}

.footer-list-link.alt {
	padding-left: 0;
}

.footer-list-link.special {
	font-size: 16px;
}

.footer-social-icon-image {
	width: 28px;
	height: 28px;
	margin: 1px;
	padding: 4px;
}

.footer-other-clinics-wrap {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 49%;
	padding-left: 64px;
	display: flex;
}

.footer-other-clinics-wrap.alt {
	padding-left: 0;
}

.footer-social-icon-link {
	background-color: #7f7e79;
	border-radius: 18px;
	margin: 5px 8px;
}

.footer-social-icon-link:hover {
	background-color: #4d4d4d;
}

.footer-social-icon-link.facebook {
	background-color: var(--secondary-gold);
	margin-left: 0;
}

.footer-social-icon-link.facebook:hover {
	background-color: var(--primary-red);
}

.footer-column-wrap {
	clear: both;
	width: 48%;
	font-style: normal;
}

.footer-column-wrap.other-locations {
	color: var(--black);
	width: 100%;
}

.footer-wide-wrapper {
	background-color: var(--primary-red);
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.grip-logo-image {
	width: 9rem;
	min-width: 150px;
	transition: all 0.45s;
}

.grip-logo-link {
	float: right;
	opacity: 0.5;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, opacity 0.2s;
}

.grip-logo-link:hover {
	opacity: 1;
}

.column-2 {
	justify-content: flex-end;
	padding-right: 0;
	display: flex;
}

.footer-signoff-links {
	opacity: 0.66;
	border-bottom: 2px solid #fff;
	margin-right: 1rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.25rem;
	display: inline-block;
}

.footer-signoff-links:hover {
	opacity: 1;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.signoff-div {
	background-color: var(--primary-red);
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 1.5rem 60px;
	display: flex;
}

.footer-signoff-link {
	color: #fff;
	font-size: 14px;
	text-decoration: none;
	transition: color 0.3s;
	border-bottom: 1px solid transparent;
}

.sign-off-div-wrap {
	width: 100%;
}

.footer-copyright-2 {
	color: var(--white);
	margin-bottom: 0;
	margin-right: 1.5rem;
	display: inline-block;
}

.footer-copyright-2 h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
}

.column-3 {
	align-items: center;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.web-development-by {
	color: #fff;
	letter-spacing: 0.045rem;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 0.65rem;
	line-height: 0.75rem;
	position: absolute;
	top: 0%;
	bottom: auto;
	left: auto;
	right: 0%;
}

.columns-2 {
	align-items: center;
	display: flex;
}

.div-block {
	display: flex;
}

.footer-copyright-2-copy {
	color: var(--black);
	margin-bottom: 0;
	margin-right: 1.5rem;
	font-size: 13px;
	line-height: 19px;
	display: inline-block;
}

.footer-signup {
	width: 49%;
}

.footer-signup.full {
	width: 100%;
}

.footer-signup.full .primary {
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}

.patient-stories {
	float: right;
	margin-top: -45px;
}

.patient-stories .primary {
	font-size: 15px !important;
	padding: 15px !important;
}

.div-block-2 {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.text-field {
	border-radius: 50px;
	height: 52px;
	margin-bottom: 0;
	display: none;
}

.link-2 {
	color: var(--black);
}

.div-block-3 {
	flex-flow: row;
	display: flex;
}

.brand-logo {
	flex: none;
	width: 350px;
	min-height: 70px;
	margin-top: -5px;
}

.nav-wrapper {
	color: var(--white);
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	font-size: 16px;
	font-weight: 400;
	display: flex;
}

.nav-wrapper.special {
	z-index: 2;
	background-color: var(--secondary-gold);
	color: var(--black);
	border-radius: 50px;
	position: relative;
}

.search-3 {
	color: #000;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-bottom: 0;
	margin-left: 5%;
	margin-right: 5%;
	padding-right: 20px;
	display: flex;
}

.exit-cb-wrapper {
	position: relative;
	top: 0%;
	bottom: 0%;
	left: 0%;
	height: 34px;
	right: 0%;
}

.nav-cover {
	z-index: -1;
	background-color: var(--primary-red);
	opacity: 0;
	width: 100%;
	height: 101%;
	position: absolute;
	top: -101%;
	bottom: auto;
	left: 0%;
	right: 0%;
	box-shadow: 1px 1px 10px -2px rgba(0, 0, 0, 0.3);
}

.search-cb-wrapper {
	height: 34px;
	position: relative;
}

.header__logo-link-copy {
	z-index: 1;
	filter: brightness(0%) invert();
	margin-left: 5%;
	margin-right: auto;
	position: absolute;
	top: 25px;
	left: 0;
	z-index: 1000000;
}

.navbar {
	-webkit-backdrop-filter: contrast(130%) brightness(90%);
	backdrop-filter: contrast(130%) brightness(90%);
	background-color: rgba(221, 221, 221, 0);
	background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	flex-flow: column;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	height: 100px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.search-input-3 {
	opacity: 1;
	letter-spacing: 1px;
	border: 1px #000;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 1rem;
}

.search-input-3:focus {
	color: #000;
	border: 1px solid #fff;
	outline: 1px solid #fff;
}

.dropdown-toggle {
	z-index: 2;
	color: var(--white);
	letter-spacing: 1px;
	text-transform: capitalize;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	flex-direction: column;
	align-items: center;
	padding: 10px 20px 16px;
	font-size: 15px;
	line-height: 1rem;
	transition: color 0.2s;
	display: flex;
	position: relative;
}

.dropdown-toggle:hover {
	color: var(--light-gold);
}

.dropdown-toggle._2 {
	color: var(--black);
	font-size: 14px;
}

.dropdown-toggle._2:hover {
	color: #fff;
}

.dropdown-toggle.last {
	padding-right: 0;
}

.dropdown-toggle.out {
	display: none;
}

.search-button-icon {
	z-index: 2;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
	border: 1px #233e99;
	border-radius: 20px;
	width: 34px;
	height: 34px;
	margin-top: 0;
	position: relative;
}

.search-button-icon:hover {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.search-button-icon:active {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-icon-white-01.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.triangle {
	z-index: 1;
	color: #c51b00;
	width: 100%;
	height: 25px;
	display: flex;
	position: absolute;
	top: -20px;
}

.icon-8 {
	display: none;
}

.exit-button-icon {
	z-index: 2;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/exit-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
	border: 1px #233e99;
	border-radius: 20px;
	width: 34px;
	height: 34px;
	margin-top: 0;
	position: relative;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.exit-button-icon:hover {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/exit-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.exit-button-icon:active {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-icon-white-01.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.navbar-wrapper {
	box-shadow: none;
	border-bottom: 1px rgba(255, 255, 255, 0.05);
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0.65rem 60px;
	display: flex;
	position: relative;
}

.search-icon {
	margin-top: 5px;
	margin-left: 40px;
	margin-right: 30px;
	display: inline-block;
}

.search-icon.desktop {
	background-color: var(--secondary-gold);
	border-radius: 50px;
	margin-top: 0;
	margin-left: 0;
	margin-right: 20px;
	display: block;
	position: relative;
}

.search-icon.desktop:hover {
	background-color: var(--primary-red);
}

.search-icon.mobile {
	background-color: var(--secondary-gold);
	border-radius: 50px;
	margin-top: 0;
	margin-left: 0;
	margin-right: 20px;
	display: none;
	position: relative;
}

.search-icon.mobile:hover {
	background-color: var(--primary-red);
}

.search-content-container {
	display: none;
}

.exit-cb-wrapper {
	display: none;
}

.red-triangle {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/gray-dropdown-triangle.svg');
	background-position: 50% 100%;
	background-repeat: no-repeat;
	background-size: contain;
	border: 1px #000;
	border-radius: 0;
	width: 25px;
	height: 15px;
	position: absolute;
	top: -15px;
	left: 1.5rem;
}

.main-nav-link-3 {
	color: #000;
	cursor: pointer;
	padding: 0.5rem 1.25rem;
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1.2rem;
	text-decoration: none;
	transition: all 0.15s;
	display: block;
	position: relative;
}

.main-nav-link-3.w--current {
	color: #f9b604;
	background-color: rgba(3, 52, 102, 0.6);
}

.main-nav-link-3.mobile-duplicate {
	display: none;
}

.search-button-2 {
	color: var(--primary-red);
	letter-spacing: 1px;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1rem;
	transition: color 0.2s;
}

.search-button-2:hover {
	color: var(--black);
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
}

.search-content {
	z-index: 1002;
	opacity: 1;
	background-color: #fff;
	border-top: 1px rgba(91, 91, 91, 0.15);
	justify-content: space-around;
	align-self: center;
	align-items: center;
	width: 100%;
	height: 80px;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 0;
	display: flex;
	position: -webkit-sticky;
	position: sticky;
	top: 0%;
	bottom: 0%;
	left: 0;
	right: 0;
}

.main-nav-dropdown {
	font-weight: 400;
}

.dropdown-nav-link {
	background-color: var(--white);
	color: var(--black);
	text-transform: capitalize;
	width: 100%;
	margin-right: 0;
	padding: 0.85rem 2rem 0.85rem 1.25rem;
	font-size: 15px;
	line-height: 1.1rem;
	text-decoration: none;
	transition: padding 0.2s, background-color 0.25s;
}

.dropdown-nav-link:hover {
	background-color: var(--secondary-gold);
	color: var(--black);
	width: 100%;
}

.dropdown-nav-link.twice {
	justify-content: space-between;
	align-items: stretch;
	padding-right: 2.25rem;
	display: flex;
}

.dropdown-nav-link.twice:hover {
	padding-right: 2rem;
}

.dropdown-nav-link.last {
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.dropdown-nav-link.first {
	border-top-right-radius: 5px;
}

.dropdown-nav-link.first-copy {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.nav-menu {
	color: var(--white);
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: 0;
	display: flex;
	position: static;
}

.widthwrapper {
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 50%;
	margin-left: auto;
	margin-right: auto;
	padding-right: 5%;
	display: flex;
}

.nav-dropdown-links-copy {
	float: left;
	background-color: #678338;
	border: 1px solid #3c4a20;
	flex-direction: column;
	align-items: stretch;
	width: 200px;
	height: auto;
	padding: 0.5rem;
	display: flex;
}

.nav-dropdown-container-2-copy {
	justify-content: center;
	width: auto;
	display: none;
}

.nav-dropdown-container-2-copy.w--open {
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	flex: 1;
	order: 1;
	width: auto;
	max-width: 1500px;
	padding-left: 0;
	font-family: Exo, sans-serif;
	display: block;
	top: -16%;
	left: 190px;
}

.nav-dropdown-container-wrap-2-copy {
	background-color: #2c4a4d;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	width: auto;
	height: auto;
	font-family: Exo, sans-serif;
	display: flex;
}

.mobile-menu-arrow-4 {
	display: none;
}

.nav-dropdown-wrapper-sub-sub {
	color: var(--black);
	border-bottom: 4px rgba(0, 0, 0, 0);
	order: -1;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0;
	transition: all 0.45s;
	display: flex;
	position: relative;
}

.nav-dropdown-wrapper-sub-sub:hover {
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 4px #799165;
}

.nav-dropdown-wrapper-sub-sub.w--open {
	color: #fff;
}

.icon-6-copy {
	z-index: 3000;
	margin-right: 10px;
	transform: rotate(-90deg);
}

.icon-6-copy:hover {
	color: var(--black);
}

.nav-links-2 {
	color: #fff;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 0;
	width: 100%;
	margin-bottom: 0.35rem;
	margin-left: 0;
	margin-right: 0;
	padding: 0.35em 0.75em 0.35em 0.75rem;
	font-size: 0.8rem;
	font-weight: 400;
	text-decoration: none;
	display: block;
}

.nav-links-2:hover {
	color: #fff;
	background-color: rgba(63, 62, 62, 0.3);
	border-style: solid;
	border-color: #fff;
}

.nav-links-2.w--current {
	color: #fff;
}

.nav-sub-sub {
	border-right: 1px rgba(128, 128, 128, 0.25);
	flex-flow: wrap;
	flex: 0 auto;
	width: auto;
	margin-left: 0;
	margin-right: 0;
	display: block;
	position: relative;
}

.dropdown {
	width: 100%;
	display: flex;
}

.dropdown-list-2 {
	z-index: 5;
	position: absolute;
	top: 4.7812px;
	left: 154px;
}

.dropdown-list-2.w--open {
	position: absolute;
}

.top-nav-div {
	z-index: 10000;
	justify-content: space-between;
	width: 100%;
	height: 50%;
	padding-top: 8px;
	display: flex;
}

.innertopnavwrap {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 5%;
	padding-right: 5%;
	display: flex;
}

.naviconwrap {
	color: var(--primary-red);
	width: 25px;
	height: 20px;
	margin-left: 16px;
	margin-right: 5px;
}

.links {
	color: #222;
	cursor: pointer;
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}

.links:hover {
	color: var(--primary-red);
}

.main-nav-dropdown-copy {
	margin-left: 16px;
	margin-right: 0;
	font-weight: 400;
}

.dropdown-toggle-copy {
	color: var(--black);
	letter-spacing: 1px;
	text-transform: capitalize;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0;
	font-size: 15px;
	line-height: 1rem;
	transition: color 0.2s;
	display: flex;
}

.dropdown-toggle-copy._2 {
	color: var(--black);
	font-size: 14px;
}

.dropdown-toggle-copy._2:hover {
	color: #fff;
}

.secondary-nav-dropdown {
	justify-content: center;
	align-self: center;
	align-items: center;
	height: 34px;
	margin-left: 0;
	margin-right: 0;
	padding-top: 16px;
	padding-bottom: 16px;
	display: flex;
}

.secondary-nav-dropdown.red {
	background-color: var(--primary-red);
	color: var(--white);
}

.dropdown-toggle-2 {
	padding-top: 0;
	padding-bottom: 0;
}

.dropdown-toggle-2.alt {
	color: var(--white);
}

.dropdown-list-3 {
	padding-top: 10px;
}

.dropdown-list-3.w--open {
	background-color: rgba(221, 221, 221, 0);
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	padding-top: 25px;
	top: 24px;
	overflow: hidden;
}

.dropdown-link-3 {
	background-color: var(--white);
}

.search-form-wrapper {
	margin-bottom: 0;
}

.search-form {
	display: flex;
}

.search-button {
	background-color: var(--secondary-gold);
	color: #fff;
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/search_white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px 15px;
	border: 3px solid #fff;
	border-radius: 50%;
	flex: none;
	width: 35px;
	height: 35px;
}

.search-button:hover {
	background-color: var(--primary-red);
}

.dropdown-2 {
	z-index: 1;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 150px;
	display: flex;
}

.navbase {
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	overflow: auto;
}

.dropdown-nav-list-copy {
	background-color: var(--creme-white);
	color: #09569b;
	padding: 8px;
	font-size: 16px;
	overflow: visible;
}

.dropdown-nav-list-copy.w--open {
	color: var(--black);
	background-color: rgba(255, 255, 255, 0);
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	min-width: 155px;
	padding: 22px 0 0;
	top: -22.2px;
	left: 100%;
	box-shadow: 4px 10px 9px -9px rgba(0, 0, 0, 0.2);
}

.dropdown-nav-list-copy.custom-scrollbar {
	border-radius: 0;
}

.icon-10 {
	transform: rotate(-90deg);
}

.div-block-4 {
	justify-content: space-between;
	align-items: center;
	width: 100%;
	display: flex;
}

.text-block-46 {
	word-break: break-all;
}

.div-block-5 {
	grid-column-gap: 64px;
	grid-row-gap: 64px;
	-webkit-backdrop-filter: brightness(105%) blur(5px);
	backdrop-filter: brightness(105%) blur(5px);
	border-radius: 20px;
	flex-flow: column;
	width: 90%;
	max-width: 1350px;
	padding: 32px 32px 64px;
	display: flex;
}

.div-block-5.alt {
	grid-column-gap: 24px;
	grid-row-gap: 24px;
}

.slide-nav {
	display: none;
}

.mask {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 40px;
	overflow: hidden;
}

.slider {
	background-color: rgba(221, 221, 221, 0);
	height: 350px;
}

.slide {
	width: 25%;
	padding-left: 16px;
	padding-right: 16px;
}

.nav-dropdown {
	color: #09569b;
	max-height: 250px;
	padding: 8px;
	font-size: 16px;
	overflow: auto;
}

.nav-dropdown.w--open {
	color: var(--black);
	background-color: rgba(255, 255, 255, 0);
	min-width: 155px;
	padding: 4px 0 0;
	left: 10px;
	overflow: visible;
	box-shadow: 4px 10px 9px -9px rgba(0, 0, 0, 0.2);
}

.nav-dropdown.custom-scrollbar {
	overflow: scroll;
}

.anotherwrapper {
	align-items: center;
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 5%;
	display: flex;
}

.navcomplete {
	z-index: 1000;
	top: auto;
	bottom: auto;
	left: 0;
	right: 0;
}

.sidebar-section-title-2 {
	color: var(--primary-red);
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 700;
	line-height: 20px;
}

.sidebar-section-title-2.white {
	color: #fff;
	margin-bottom: 0.5rem;
}

.main-content-column {
	padding-left: 0;
	padding-right: 0;
}

.inside-page-header-wrap {
	border-bottom: 0.5px solid rgba(51, 51, 51, 0.15);
	margin-bottom: 2rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.link-7 {
	color: #131313;
	font-weight: 400;
	text-decoration: none;
}

.link-7:hover {
	color: var(--primary-red);
	text-decoration: none;
}

.link-7.w--current {
	color: #28313b;
	font-weight: 700;
}

.breadcrumb-list-item {
	float: left;
	color: var(--black);
	justify-content: center;
	align-items: flex-start;
	margin-right: 0.5rem;
	padding-top: 0;
	font-size: 1rem;
	display: flex;
}

.block-quote-2 {
	background-color: rgba(216, 210, 188, 0.72);
	background-image: url('/20181016082423/assets/images/gradient-gold---20opacity-reflected.svg');
	background-position: 100% 0;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: 32px;
	margin-bottom: 32px;
	padding: 32px;
	font-size: 1.5rem;
	font-style: italic;
	font-weight: 400;
	line-height: 1.75rem;
	display: flex;
}

.h2-header-link {
	color: var(--primary-red);
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 2rem;
	font-weight: 400;
	line-height: 2.25rem;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h2-header-link:hover {
	color: var(--black);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.left-nav-nested-list-link {
	color: #333;
	text-transform: capitalize;
	border: 2px rgba(0, 0, 0, 0);
	padding: 0.75em 1.5rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.85em;
	font-weight: 500;
	line-height: 1.375em;
	text-decoration: none;
	display: block;
}

.left-nav-nested-list-link:hover {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	border: 2px #48bdd7;
}

.left-nav-nested-list-link.w--current {
	color: #5e8dbe;
}

.left-nav-nested-list-link.w--current:hover {
	color: #0915f8;
}

.left-nav-nested-list-link.grandchild-nav-link {
	color: #1a2238;
	border-radius: 25px;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-nav-nested-list-link.grandchild-nav-link:hover {
	color: var(--primary-red);
	background-color: rgba(216, 210, 188, 0.3);
	border-radius: 20px;
}

.left-nav-nested-list-link.grandchild-nav-link.great {
	color: #1a2238;
	padding-left: 2.5rem;
}

.left-nav-nested-list-link.grandchild-nav-link.great:hover {
	color: var(--primary-red);
}

.div-block-151 {
	background-image: url('/20181016082423/assets/images/anchor-icon-asset-blue.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	background-attachment: scroll;
	width: 90px;
	height: 125px;
	margin-right: 16px;
}

.horiz-divider-2 {
	color: #003c43;
	background-color: rgba(91, 91, 91, 0.15);
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.current {
	color: var(--primary-red);
	text-transform: capitalize;
	vertical-align: baseline;
	border-bottom: 1px solid rgba(0, 0, 0, 0);
	font-size: 1em;
	line-height: 1em;
	display: inline;
}

.inside-pages-paragraph {
	margin-bottom: 1rem;
	padding-bottom: 0;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.inside-pages-paragraph.list-item {
	margin-bottom: 0;
}

.inside-pages-paragraph.underline {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	margin-bottom: 2rem;
	padding-bottom: 2rem;
}

.left-nav-list-link {
	color: #131313;
	text-transform: capitalize;
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: block;
}

.left-nav-list-link:hover {
	background-color: var(--creme-white);
	color: var(--primary-red);
	border-radius: 25px;
	text-decoration: none;
}

.content-container {
	background-color: #fff;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	width: 50%;
	max-width: none;
	margin-left: 0;
	margin-right: 0;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	display: block;
}

.content-container.threequarterwidth {
	width: 100%;
	max-width: none;
	padding-left: 0;
	padding-right: 0;
}

.left-nav-nested-list {
	background-color: rgba(216, 210, 188, 0.3);
	border-top: 2px #498fa3;
	border-radius: 16px;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.quote-content {
	border-left-style: none;
	margin-bottom: 0;
	padding: 0;
	font-size: 1.3rem;
	font-style: italic;
	line-height: 1.75rem;
}

.ordered-list-2 {
	margin-bottom: 1rem;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.sidebar-list-item {
	color: #0915f8;
	margin-bottom: 0.5rem;
	padding-left: 0;
	font-family: Montserrat, sans-serif;
	text-decoration: none;
}

.inside-content-beckground {
	background-color: #fff;
	max-width: 1250px;
	margin-bottom: 6px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	padding-bottom: 4rem;
	display: block;
	position: relative;
	box-shadow: 0 -20px 20px -4px rgba(0, 0, 0, 0.16);
}

.heading-10 {
	color: var(--primary-red);
	text-transform: capitalize;
	margin-bottom: 5px;
	font-size: 18px;
	font-weight: 700;
}

.h6-header-link {
	color: var(--primary-red);
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h6-header-link:hover {
	color: var(--black);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.left-nav-nested-list-item {
	box-shadow: none;
	margin-bottom: 0;
}

.left-nav-list-content-wrap {
	border-right: 1px rgba(0, 0, 0, 0.05);
	height: auto;
	margin-top: 0;
	margin-right: 2rem;
	padding-bottom: 1.5rem;
	display: block;
	overflow: hidden;
}

.intro-paragraph-2 {
	color: #333;
	margin-bottom: 1rem;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.5rem;
}

.h3-header-link {
	color: var(--primary-red);
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h3-header-link:hover {
	color: var(--black);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.h4-header-link {
	color: var(--primary-red);
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 22px;
	font-weight: 400;
	line-height: 24px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h4-header-link:hover {
	color: var(--black);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 0, 0, 0);
	font-weight: 400;
}

.heading-18-copy-copy {
	margin-bottom: 5px;
	font-size: 32px;
	font-weight: 700;
}

.button-styling {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	align-items: center;
	padding-left: 0;
	font-family: Open Sans, sans-serif;
	font-weight: 800;
	display: flex;
}

.heading-18-copy {
	color: var(--primary-red);
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: 700;
}

.breadcrumb-list {
	text-transform: uppercase;
	margin: 0 0.125rem;
	padding-left: 0;
	font-size: 0.6875rem;
	line-height: 1em;
}

.section-wrap {
	padding-top: 100px;
	overflow: hidden;
}

.unordered-list {
	margin-bottom: 1rem;
}

.main-content-section {
	color: #131313;
	width: 100%;
	max-width: none;
	margin-right: 2rem;
}

.paragraph-12 {
	color: #333;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.heading-18 {
	color: var(--primary-red);
	margin-bottom: 5px;
	font-size: 22px;
	font-weight: 700;
}

.heading-18.button-style {
	margin-bottom: 1rem;
}

.button {
	z-index: 5;
	color: #fff;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	cursor: pointer;
	background-color: #097372;
	border-radius: 20px;
	padding: 0.75rem 1.25rem;
	font-size: 0.9rem;
	font-weight: 500;
	transition: all 0.4s, background-color 0.4s;
	position: static;
	top: auto;
}

.button:hover {
	opacity: 1;
	color: #fff;
	background-color: #1d1e50;
}

.button.alt {
	color: #1d1e50;
	background-color: #fff;
	border: 1px solid #097372;
}

.button.alt:hover {
	color: #fff;
	background-color: #097372;
}

.left-nav-list {
	box-shadow: none;
	margin-top: 0;
	margin-bottom: 1.5rem;
	padding-left: 0;
}

.sidebar-section-wrap {
	border-top: 1px rgba(0, 0, 0, 0.15);
	line-height: 1.35rem;
	position: relative;
}

.main-content-wrapper {
	margin-left: auto;
	margin-right: auto;
	padding-left: 64px;
	padding-right: 64px;
	display: block;
}

.breadcrumb-link-1 {
	color: var(--black);
	text-transform: none;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0);
	text-decoration: none;
	display: inline;
}

.breadcrumb-link-1:hover {
	border-bottom-style: solid;
	border-bottom-color: var(--primary-red);
}

.breadcrumb-link-1.w--current {
	color: #28313b;
	font-weight: 700;
}

.breadcrumb-link-1.breadcrumb-link-2 {
	color: #333;
	text-transform: capitalize;
	border-bottom-color: rgba(51, 51, 51, 0.35);
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1rem;
	text-decoration: none;
}

.breadcrumb-link-1.breadcrumb-link-2:hover {
	color: #097372;
	border-bottom-style: none;
	border-bottom-color: rgba(9, 21, 248, 0.35);
}

.left-nav-column {
	padding-left: 0;
	padding-right: 0;
	display: block;
}

.left-nav-list-item {
	box-shadow: none;
	margin-bottom: 0;
}

.table-div {
	color: #131313;
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
}

.inner-image-content {
	background-image: url('/20181016082423/assets/images/GettyImages-1180123651.jpg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: auto;
	height: 100%;
}

.inner-image-content.recent-blog-post {
	background-image: url('/20181016082423/assets/images/Belmond_Campus_620141_74D76C9035F18.jpg');
	background-position: 50%;
	background-repeat: repeat;
	background-size: cover;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: flex;
}

.paragraph-16 {
	color: var(--black);
	font-family: Montserrat, sans-serif;
	font-size: 16px;
}

.paragraph-16.white {
	color: var(--white);
}

.sidebar-list {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	margin-top: 0;
	margin-bottom: 0.75rem;
	padding-left: 0;
	display: flex;
}

.image-block {
	width: 75%;
	height: 375px;
	position: relative;
	overflow: hidden;
}

.image-block.inside-page {
	width: 100%;
	height: 300px;
	margin-bottom: 2rem;
}

.heading-6 {
	color: var(--primary-red);
	background-color: rgba(0, 0, 0, 0);
	width: 100%;
	margin-top: 0;
	margin-bottom: 2rem;
	padding: 0;
	font-size: 2.75rem;
}

.h5-header-link {
	color: var(--primary-red);
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 18px;
	font-weight: 400;
	line-height: 20px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h5-header-link:hover {
	color: var(--black);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.heading-10-copy {
	color: var(--primary-red);
	text-transform: capitalize;
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: 700;
}

.breadcrumb-divider {
	float: right;
	margin-left: 0.5rem;
}

.inside-page-background-image {
	background-color: #161e33;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	height: 200px;
	margin-left: -15px;
	margin-right: -15px;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	position: relative;
}

.main-content-column-nested-2 {
	padding-left: 0;
	padding-right: 0;
}

.breadcrumb-current {
	color: var(--primary-red);
	text-transform: capitalize;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0);
	text-decoration: none;
	display: inline;
}

.breadcrumb-current:hover {
	border-bottom-style: solid;
	border-bottom-color: var(--primary-red);
}

.breadcrumb-current.w--current {
	color: #28313b;
	font-weight: 700;
}

.breadcrumb-current.breadcrumb-link-2 {
	color: #333;
	text-transform: capitalize;
	border-bottom-color: rgba(51, 51, 51, 0.35);
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1rem;
	text-decoration: none;
}

.breadcrumb-current.breadcrumb-link-2:hover {
	color: #097372;
	border-bottom-style: none;
	border-bottom-color: rgba(9, 21, 248, 0.35);
}

.div-block-152 {
	background-color: rgba(216, 210, 188, 0.65);
	width: 35%;
	height: 100%;
	padding: 32px;
	font-size: 16px;
}

.primary-btn-copy {
	z-index: 1;
	background-color: var(--light-gold);
	color: var(--black);
	text-align: center;
	white-space: normal;
	word-break: normal;
	border-radius: 50px;
	min-width: 150px;
	margin-left: 0;
	margin-right: 0;
	padding: 16px;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	position: relative;
	overflow: hidden;
}

.primary-btn-copy:hover {
	background-color: var(--secondary-gold);
}

.primary-btn-copy.red {
	background-color: var(--primary-red);
	color: var(--white);
	text-transform: capitalize;
	white-space: nowrap;
	border-radius: 50px;
	overflow: visible;
}

.primary-btn-copy.red:hover {
	background-color: #460010;
}

.primary-btn-copy.red._150 {
	min-width: 150px;
}

.primary-btn-copy.alt {
	border: 1px solid var(--light-gold);
	padding: 10px 0;
	font-size: 15px;
	overflow: visible;
}

.div-block-153 {
	display: flex;
}

.inside-content-beckground-full {
	background-color: #fff;
	max-width: 1000px;
	margin-bottom: 6px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 32px;
	padding-left: 64px;
	padding-right: 64px;
	display: block;
	position: relative;
	box-shadow: 0 -20px 20px -4px rgba(0, 0, 0, 0.16);
}

.main-content-section-copy {
	color: #131313;
	width: 100%;
	max-width: none;
}

.main-content-wrapper-full {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.div-block-152-copy {
	background-color: rgba(216, 210, 188, 0.65);
	border-radius: 25px;
	height: 100%;
	margin-left: 2rem;
	padding: 32px;
	font-size: 16px;
}

.div-block-152-copy._35width {
	width: 35%;
}

.tertiary {
	color: var(--primary-red);
	align-items: center;
	min-height: 50px;
	font-weight: 700;
	text-decoration: none;
	transition: color 0.2s;
	display: flex;
	position: relative;
}

.tertiary:hover {
	text-decoration: underline;
}

.left-side-secondary-content-box {
	background-color: var(--primary-red);
	border-radius: 25px;
	height: 100%;
	margin-right: 32px;
	padding: 32px;
	font-size: 16px;
}

.left-side-secondary-content-box.left {
	margin-right: 0;
}

.pinpoint-diagram {
	z-index: 3;
	background-image: url('/20181016082423/assets/images/skeleton.svg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: auto;
	justify-content: flex-end;
	align-items: center;
	width: 41%;
	height: 550px;
	margin-top: 32px;
	margin-bottom: 32px;
	display: flex;
	position: relative;
	bottom: -5px;
}

.pinpoint-content-container {
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin-left: auto;
	display: flex;
}

.button-2 {
	color: #fff;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	background-color: #8b3c45;
	padding: 1rem;
	font-size: 0.85rem;
	font-weight: 700;
	line-height: 1rem;
	transition: background-color 0.35s, all 0.3s;
}

.button-2:hover {
	background-color: #742831;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

.button-2.find-a-specialist {
	background-color: #534d42;
}

.button-2.find-a-specialist:hover {
	background-color: #403a2f;
}

.text-block {
	color: #534d42;
	text-align: center;
	max-width: 1000px;
	margin-bottom: 1.5rem;
	font-size: 1.1rem;
	line-height: 1.75rem;
}

.text-block.left-justified {
	color: #534d42;
	text-align: left;
	margin-bottom: 1.5rem;
	font-size: 1.1rem;
}

.pinpoint-btn {
	z-index: 1000;
	opacity: 0.8;
	background-color: #313542;
	background-image: url('/20181016082423/assets/images/plus_add_wht.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 60%;
	border: 3px solid #fcc40d;
	border-radius: 100px;
	width: 45px;
	height: 45px;
	margin-left: auto;
	margin-right: auto;
	font-weight: 900;
	text-decoration: none;
	transition: opacity 0.3s, border-color 0.3s, background-color 0.3s;
	display: block;
	position: absolute;
	top: 65%;
	left: 60%;
	box-shadow: 0 0 6px 4px rgba(27, 30, 41, 0.15);
}

.pinpoint-btn:hover {
	opacity: 1;
	background-color: rgba(252, 196, 13, 0.9);
	border-color: #fff;
}

.pinpoint-btn.pinpoint-3 {
	z-index: 10;
	opacity: 1;
	background-color: rgba(158, 146, 94, 0.4);
	background-size: 45%;
	border-color: #9e925e;
	width: 35px;
	height: 35px;
	top: 95px;
	left: 165px;
}

.pinpoint-btn.pinpoint-2 {
	z-index: 10;
	opacity: 1;
	background-color: rgba(158, 146, 94, 0.4);
	background-size: 45%;
	border-color: #9e925e;
	width: 35px;
	height: 35px;
	top: 160px;
	left: 95px;
}

.pinpoint-btn.pinpoint-1 {
	z-index: 10;
	opacity: 1;
	background-color: rgba(158, 146, 94, 0.4);
	background-position: 50%;
	background-size: 45%;
	border-color: #9e925e;
	width: 35px;
	height: 35px;
	top: 205px;
	left: 35px;
}

.pinpoint-btn.pinpoint-1:hover {
	transform: none;
}

.pinpoint-btn.pinpoint-5 {
	z-index: 10;
	opacity: 1;
	background-color: rgba(158, 146, 94, 0.4);
	background-size: 45%;
	border-color: #9e925e;
	width: 35px;
	height: 35px;
	top: 260px;
	left: 185px;
}

.pinpoint-btn.pinpoint-7 {
	z-index: 10;
	opacity: 1;
	background-color: rgba(158, 146, 94, 0.4);
	background-size: 45%;
	border-color: #9e925e;
	width: 35px;
	height: 35px;
	top: 495px;
	left: 190px;
}

.pinpoint-btn.pinpoint-6 {
	z-index: 10;
	opacity: 1;
	background-color: rgba(158, 146, 94, 0.4);
	background-size: 45%;
	border-color: #9e925e;
	width: 35px;
	height: 35px;
	top: 375px;
	left: 190px;
}

.pinpoint-btn.pinpoint-4 {
	z-index: 10;
	opacity: 1;
	background-color: rgba(158, 146, 94, 0.4);
	background-size: 45%;
	border-color: #9e925e;
	width: 35px;
	height: 35px;
	top: 195px;
	left: 220px;
}

.subtitle-heading {
	color: #8b3c45;
	letter-spacing: 0.075em;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 0.25rem;
	font-size: 1.1rem;
	font-weight: 900;
	line-height: 1.45rem;
}

.subtitle-heading.pinpoint-heading {
	margin-bottom: 1.5rem;
}

.subtitle-heading.testimonials-heading {
	color: var(--white);
	margin-bottom: 2.5rem;
	font-size: 32px;
}

.pinpoint-text {
	color: #8b3c45;
	letter-spacing: 0.075em;
	text-transform: uppercase;
	font-size: 0.85rem;
	font-weight: 900;
	line-height: 0.85rem;
}

.pinpoint-color-overlay {
	z-index: -1;
	opacity: 1;
	background-image: linear-gradient(270deg, #d8d2bc 32%, #faf8f5 93%);
	height: 100%;
	margin-top: auto;
	margin-bottom: auto;
	position: absolute;
	top: auto;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.section-heading-wrapper {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.section-heading-wrapper.pinpoint {
	align-items: flex-start;
}

.pinpoint-inner-content {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 50%;
	height: 435px;
	padding: 4rem;
	display: flex;
}

.home-section {
	background-color: #fff;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	width: 100%;
	display: flex;
	position: relative;
}

.home-section.pinpoint-pain {
	background-color: rgba(0, 0, 0, 0);
	align-items: flex-end;
}

.pinpoint-text-wrapper {
	text-align: center;
	background-color: #fff;
	align-items: center;
	max-width: none;
	padding: 0.35rem 0.5rem;
	line-height: 14px;
	display: flex;
	position: absolute;
	top: 245px;
	left: 20px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.pinpoint-text-wrapper._3 {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	top: 101px;
	left: 210px;
}

.pinpoint-text-wrapper._4 {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	top: 201px;
	left: 265px;
}

.pinpoint-text-wrapper._2 {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	top: 130px;
	left: 65px;
}

.pinpoint-text-wrapper._7 {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	top: 501px;
	left: 235px;
}

.pinpoint-text-wrapper._6 {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	top: 383px;
	left: 235px;
}

.pinpoint-text-wrapper._5 {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	top: 267px;
	left: 230px;
}

.section-container {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1500px;
	padding: 4rem;
	display: flex;
}

.section-container.pinpoint-pain {
	padding-top: 0;
	padding-bottom: 0;
}

.section-container.services-section {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.section-container.patient-stories-section {
	justify-content: center;
	align-items: center;
	max-width: 1250px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 6rem;
	padding-bottom: 0;
}

.text-block-47 {
	color: #534d42;
	text-align: center;
	max-width: 1000px;
	margin-bottom: 1.5rem;
	font-size: 1.1rem;
	line-height: 1.75rem;
}

.service-icon-wrapper {
	background-color: rgba(216, 210, 188, 0.15);
	border: 3.5px #d8d2bc;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 150px;
	margin-bottom: 0.5rem;
	padding: 0;
	display: flex;
}

.service-icon-wrapper:hover {
	border-style: solid;
	border-width: 2px;
}

.service-heading {
	color: #534d42;
	letter-spacing: 0.075em;
	text-transform: uppercase;
	font-size: 1.1rem;
	font-weight: 900;
	line-height: 1.45rem;
}

.service-cb {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 25%;
	height: 200px;
	text-decoration: none;
	display: flex;
}

.service-icon {
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	border: 2px #d8d2bc;
	border-radius: 100px;
	width: 110px;
	height: 110px;
	margin-bottom: 0;
}

.section-heading {
	color: #696864;
	text-align: center;
	max-width: 800px;
	margin-top: 0;
	margin-bottom: 1.5rem;
	font-size: 2rem;
	font-weight: 400;
	line-height: 2rem;
}

.services-content-container {
	flex-direction: column;
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 0;
	display: flex;
}

.service-row {
	flex-direction: row;
	margin-top: 0;
	margin-bottom: 3rem;
	display: flex;
}

.service-row._2 {
	margin-top: 2rem;
	margin-bottom: 0;
}

.quote-text-wrapper {
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding: 0;
	display: flex;
}

.left-arrow {
	background-image: url('/20181016082423/assets/images/white-arrow-left.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	width: 50px;
	height: 50px;
}

.right-arrow {
	background-image: url('/20181016082423/assets/images/white-arrow-right.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	width: 50px;
	height: 50px;
}

.icon-12 {
	background-color: #3f26ff;
	border-radius: 50px;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	padding-top: 0;
	font-size: 1rem;
	transition: background-color 0.2s;
	display: none;
}

.icon-12:hover {
	background-color: #1700c7;
}

.attribution {
	color: var(--white);
	text-align: left;
	text-transform: none;
	justify-content: center;
	align-self: flex-start;
	align-items: center;
	width: 100%;
	padding-bottom: 0.125rem;
	padding-left: 0;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.15rem;
	display: inline;
}

.attribution.bold {
	color: var(--white);
	font-size: 0.9rem;
	font-weight: 700;
	line-height: 1.15rem;
}

.quote-text {
	color: var(--white);
	text-align: left;
	width: 100%;
	max-width: none;
	margin-bottom: 2.5rem;
	padding: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.65rem;
}

.slide-nav-3 {
	font-size: 0.5rem;
	display: none;
}

.quote-wrapper {
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
}

.quote-wrapper._2 {
	background-color: rgba(0, 0, 0, 0);
	border: 4px #fff;
	justify-content: center;
	align-items: flex-start;
	width: auto;
	height: 100%;
	margin-top: 0;
	margin-bottom: 0;
}

.testimonial-slide {
	background-color: rgba(0, 0, 0, 0);
	align-items: center;
	width: 100%;
	max-width: none;
	height: auto;
	margin-top: 0;
	margin-bottom: 0;
	display: block;
	position: static;
	top: auto;
	bottom: auto;
	left: auto;
	right: 0%;
}

.testimonial-img {
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-top-left-radius: 0;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	width: 70%;
	height: 500px;
}

.testimonial-right-slide-arrow {
	background-color: var(--secondary-gold);
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	margin-left: auto;
	margin-right: 0;
	transition: opacity 0.2s;
	display: flex;
	top: auto;
	bottom: 1rem;
	left: auto;
	right: 0rem;
	z-index: 10000;
}

.testimonial-right-slide-arrow:hover {
	background-color: #460010;
}

.icon-13 {
	background-color: #3f26ff;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	padding-top: 0;
	font-size: 1rem;
	transition: background-color 0.2s;
	display: none;
}

.icon-13:hover {
	background-color: #1700c7;
}

.testimonial-left-slide-arrow {
	background-color: var(--secondary-gold);
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	margin-left: 0;
	transition: all 0.2s;
	display: flex;
	top: auto;
	bottom: 1rem;
	left: auto;
	right: 3.5rem;
	z-index: 1000;
}

.testimonial-left-slide-arrow:hover {
	background-color: #460010;
}

.testimonials-container {
	justify-content: space-between;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	position: relative;
}

.testimonial-slide-wrapper {
	background-color: var(--primary-red);
	background-image: none;
	border-top-left-radius: 20px;
	border-top-right-radius: 50px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 43%;
	height: 400px;
	padding: 4rem;
	display: flex;
	position: absolute;
	top: auto;
	bottom: 0%;
	left: auto;
	right: 0%;
}

.logofooter {
	background-image: url('/20181016082423/assets/images/Ortho_ISH_Logo_Color.svg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: contain;
	width: 100%;
	min-height: 100px;
}

.articleimg {
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 10px;
	width: 100%;
	height: 200px;
}

.text-block-48 {
	color: var(--white);
	font-size: 16px;
}

.slider-2 {
	width: 100%;
	height: 100%;
}

.slide-2 {
	background-position: 50% 0;
	background-size: cover;
	padding-top: 100px;
}

.slide-3 {
	background-position: 50%;
	background-size: cover;
	padding-top: 100px;
}

.hero-area {
	width: 100%;
	height: 100%;
}

@media screen and (min-width: 1440px) {
	.hero-message {
		-webkit-backdrop-filter: brightness(141%) contrast(130%) blur(9px);
		backdrop-filter: brightness(141%) contrast(130%) blur(9px);
		margin-right: 10%;
	}

	.hero-heading {
		font-size: 3vw;
	}

	.input-under-hero-section {
		flex-flow: row;
		justify-content: space-between;
		align-items: center;
		padding-left: 10%;
		padding-right: 10%;
	}

	.cta-buttons {
		justify-content: center;
		align-items: flex-end;
		width: 27.67%;
		min-width: auto;
		margin-left: 0;
		margin-right: 0;
	}

	.quick-links {
		grid-column-gap: 8px;
		grid-row-gap: 8px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		align-content: center;
		justify-content: center;
		margin-right: 0%;
		display: grid;
	}

	.textwithicon {
		width: 85px;
	}

	.field-container {
		padding-left: 32px;
		padding-right: 12px;
	}

	.newssection,
	.specialtiesservicessection {
		padding-left: 10%;
		padding-right: 10%;
	}

	.primary-btn.red {
		padding-left: 20px;
		padding-right: 20px;
	}

	.clinicssection {
		padding-left: 10%;
		padding-right: 10%;
	}

	.search-3 {
		margin-left: 10%;
		margin-right: 10%;
	}

	.header__logo-link-copy {
		margin-left: 10%;
	}

	.navbar {
		height: 100px;
	}

	.dropdown-toggle {
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-icon.desktop,
	.search-icon.mobile {
		margin-right: 0;
	}

	.widthwrapper {
		padding-right: 10%;
	}

	.innertopnavwrap {
		padding-top: 8px;
		padding-right: 10%;
	}

	.dropdown-2 {
		justify-content: center;
		align-items: center;
		min-width: 165px;
		display: flex;
	}

	.div-block-4 {
		justify-content: space-between;
		align-items: center;
		display: flex;
	}

	.nav-dropdown.w--open {
		padding-top: 17px;
	}

	.anotherwrapper {
		padding-left: 0%;
	}

	.primary-btn-copy.red {
		padding-left: 20px;
		padding-right: 20px;
	}

	.testimonial-right-slide-arrow,
	.testimonial-left-slide-arrow {
		background-color: var(--secondary-gold);
	}
}

@media screen and (max-width: 991px) {
	.patient-stories {
		margin-top: 5px;
	}

	.hero-message {
		width: 44vw;
		margin-right: 0%;
		padding-bottom: 32px;
		padding-left: 32px;
	}

	.hero-heading {
		font-size: 5vw;
	}

	.input-under-hero-section {
		height: auto;
	}

	.tabs1_link-service {
		width: 80%;
		margin-left: 0.25rem;
		margin-right: 0.25rem;
		padding: 0.5rem 1.5rem;
	}

	.tabs1_link-service.w--current {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}

	.tabs1_link-service.horizontal {
		width: 100%;
		max-width: none;
	}

	.quick-links {
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin-left: 0;
		margin-right: 0%;
		display: flex;
	}

	.hero-section,
	.newssection {
		overflow: hidden;
	}

	.advant__block {
		padding-top: 16px;
		padding-left: 16px;
		padding-right: 16px;
	}

	.advant__columns {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
	}

	.p {
		text-align: center;
	}

	.p.mod--advant {
		text-align: left;
	}

	.specialtiesservicessection {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
	}

	.tabs {
		height: auto;
	}

	.txtdiv {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
	}

	.regular-paragraph {
		max-width: none;
	}

	.button-text {
		white-space: nowrap;
		margin-top: -5px;
		margin-right: 0;
		line-height: 16px;
	}

	.imagewrapper {
		height: 150px;
	}

	.footer-column-title {
		text-align: center;
	}

	.footer-column-title._2 {
		text-align: left;
	}

	.footer-address-column {
		width: 100%;
	}

	.footer-links-list-item {
		width: auto;
		margin-bottom: 0.75em;
	}

	.footer-links-list {
		flex-flow: column;
		justify-content: space-between;
		display: flex;
	}

	.footer-content-row {
		flex-flow: wrap;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.footer-content-wrap {
		padding-top: 3rem;
		padding-left: 32px;
		padding-right: 32px;
	}

	.footer-other-clinics-wrap {
		width: 47%;
	}

	.footer-column-wrap.other-locations {
		margin-top: 1.5em;
	}

	.grip-logo-link {
		float: right;
		margin-bottom: 1rem;
		margin-left: 0;
		margin-right: 0;
	}

	.column-2 {
		flex-direction: column;
		align-items: flex-end;
		padding-right: 0;
	}

	.footer-signoff-links {
		margin-left: 0;
		margin-right: 0.75rem;
	}

	.footer-signoff-link-wrap {
		float: left;
		margin-bottom: 1.5rem;
		display: block;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
	}

	.footer-copyright-2 {
		float: left;
		margin-bottom: 0.25rem;
		margin-right: 0;
	}

	.column-3 {
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.footer-copyright-2-copy {
		float: left;
		max-width: 300px;
		margin-bottom: 0.25rem;
		margin-right: 0;
	}

	.footer-signup {
		flex-flow: column;
		justify-content: flex-start;
		align-self: center;
		align-items: center;
		width: 50%;
		margin-left: -11px;
		display: flex;
	}

	.div-block-3 {
		flex-flow: row;
	}

	.mobile-arrow {
		margin-left: 1.25rem;
		margin-right: 1.25rem;
		font-family: Montserrat, sans-serif;
		font-weight: 500;
	}

	.mobile-arrow:hover {
		color: var(--black);
	}

	.nav-wrapper {
		width: 100%;
		display: none;
	}

	.search-3 {
		margin-left: 0%;
		margin-right: 0%;
		padding-right: 0;
	}

	.menu-button {
		color: #fff;
		padding: 0;
		font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
		font-size: 2rem;
		font-weight: 400;
		line-height: 2rem;
	}

	.menu-button.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.navbar {
		z-index: 1050;
		margin-top: -1px;
		display: flex;
		top: auto;
		bottom: auto;
		left: auto;
		right: auto;
	}

	.two-part-mobile-nav-wrapper {
		border-top: 1px #000;
		border-bottom: 1px solid rgba(77, 76, 76, 0.25);
		justify-content: space-between;
		display: flex;
	}

	.two-part-mobile-nav-wrapper.top {
		border-top-style: none;
		border-top-color: rgba(77, 76, 76, 0.25);
		border-bottom-style: none;
		border-bottom-color: rgba(77, 76, 76, 0.25);
		border-left: 5px rgba(0, 0, 0, 0);
		flex-direction: row;
		justify-content: space-between;
		font-family: Montserrat, sans-serif;
		transition: all 0.2s;
	}

	.two-part-mobile-nav-wrapper.top:hover {
		border-left: 4px solid var(--secondary-gold);
		background-color: rgba(179, 170, 126, 0.33);
	}

	.two-part-mobile-nav-wrapper.top.special-red {
		background-color: var(--primary-red);
		color: var(--white);
	}

	.two-part-mobile-nav-wrapper.top.special-white {
		background-color: var(--secondary-gold);
	}

	.search-input-3 {
		padding-left: 0;
	}

	.dropdown-toggle {
		display: none;
	}

	.mobile-menu-arrow {
		color: var(--black);
		background-color: rgba(0, 0, 0, 0);
		border-left: 1px rgba(77, 76, 76, 0.25);
		justify-content: center;
		align-items: center;
		width: 12%;
		display: flex;
	}

	.mobile-menu-arrow:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.mobile-menu-arrow.white {
		color: var(--white);
	}

	.navbar-wrapper {
		background-color: rgba(0, 0, 0, 0);
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.search-icon {
		margin-top: 10px;
	}

	.search-icon.desktop {
		margin-top: 0;
		margin-left: auto;
		margin-right: 2rem;
		padding-left: 0;
	}

	.search-icon.mobile {
		margin-top: 0;
		margin-left: auto;
		margin-right: 1rem;
		padding-left: 0;
		display: block;
	}

	.main-nav-link-3 {
		text-align: left;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}

	.main-nav-link-3.mobile-duplicate {
		letter-spacing: 1px;
		text-transform: uppercase;
		cursor: pointer;
		border-bottom-style: none;
		justify-content: space-between;
		width: 88%;
		padding: 20px 3rem;
		line-height: 1rem;
		transition: color 0.2s;
		display: block;
	}

	.main-nav-link-3.mobile-duplicate:hover {
		transform: none;
	}

	.main-nav-link-3.mobile-duplicate.w--current {
		color: #f9b604;
		transform: scale(1);
		box-shadow: inset 6px 0 #f9b604;
	}

	.main-nav-link-3.mobile-duplicate.white {
		color: var(--white);
	}

	.search-content {
		border-top: 1px solid rgba(91, 91, 91, 0.15);
		width: 100vw;
		margin-bottom: 0;
		margin-left: 0%;
		margin-right: 0;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.nav-menu {
		background-color: var(--light-gold);
		color: var(--black);
		flex: 1;
		justify-content: flex-start;
		width: 100%;
		height: 456vh;
		margin: 0;
		padding-bottom: 32px;
		display: block;
		position: absolute;
		left: 0;
		right: 0;
	}

	.mobile-menu-arrow-4 {
		color: #000;
		border-bottom: 1px solid rgba(209, 184, 166, 0.15);
		border-left: 1px solid rgba(209, 184, 166, 0.15);
		justify-content: center;
		align-items: center;
		width: 12%;
		height: 47px;
		display: flex;
	}

	.mobile-menu-arrow-4:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.nav-dropdown-wrapper-sub-sub {
		border-bottom-style: none;
		flex: 0 auto;
		width: 100%;
		display: flex;
		box-shadow: inset -4px 0 rgba(0, 0, 0, 0);
	}

	.nav-dropdown-wrapper-sub-sub:hover {
		border-bottom-style: none;
		box-shadow: inset -4px 0 rgba(0, 0, 0, 0);
	}

	.icon-6-copy {
		display: none;
	}

	.mobile-arrow-4 {
		color: #5b5b5b;
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}

	.nav-sub-sub {
		background-color: #fff;
		width: 100%;
		height: auto;
		position: static;
	}

	.top-nav-div {
		display: none;
	}

	.div-block-5 {
		width: 100%;
	}

	.slider {
		height: auto;
	}

	.slide {
		width: 33.33%;
	}

	.anotherwrapper {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding-right: 5%;
	}

	.icon-11 {
		z-index: 5;
		position: relative;
	}

	.right-sidebar-column {
		border-top: 3px #48bdd7;
		margin-top: 0;
		padding-top: 0;
	}

	.main-content-column {
		padding-left: 0;
		padding-right: 0;
	}

	.inside-page-header-wrap {
		margin-bottom: 1rem;
		padding-top: 0;
		padding-bottom: 1rem;
	}

	.content-container,
	.content-container.threequarterwidth {
		width: 100%;
		padding-left: 0;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding: 1.5rem 40px 2rem;
	}

	.inside-row-nested {
		flex-flow: column;
		width: 100%;
		display: flex;
	}

	.left-nav-list-content-wrap {
		height: 0;
		overflow: hidden;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.main-content-wrapper {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.image-block {
		width: 65%;
	}

	.inside-page-background-image {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.div-block-152 {
		width: 100%;
		margin-top: 32px;
	}

	.div-block-153 {
		flex-flow: column;
	}

	.inside-content-beckground-full {
		max-width: 100%;
		padding: 1.5rem 40px 2rem;
	}

	.main-content-wrapper-full {
		width: 100%;
	}

	.div-block-152-copy {
		width: 100%;
		margin-top: 32px;
		margin-left: 0;
	}

	.div-block-152-copy._35width {
		width: 100%;
	}

	.left-side-secondary-content-box {
		width: 100%;
		margin-top: 32px;
	}

	.pinpoint-diagram {
		background-position: 50%;
		width: 670px;
		margin-left: auto;
		margin-right: auto;
	}

	.pinpoint-content-container {
		flex-flow: wrap;
	}

	.text-block.left-justified {
		text-align: center;
	}

	.pinpoint-btn {
		border-width: 2px;
		width: 34px;
		height: 34px;
	}

	.pinpoint-btn.pinpoint-3 {
		border-width: 3px;
		left: 265px;
	}

	.pinpoint-btn.pinpoint-2 {
		border-width: 3px;
		left: 195px;
	}

	.pinpoint-btn.pinpoint-1 {
		border-width: 3px;
		left: 135px;
	}

	.pinpoint-btn.pinpoint-5,
	.pinpoint-btn.pinpoint-7,
	.pinpoint-btn.pinpoint-6 {
		border-width: 3px;
		left: 285px;
	}

	.pinpoint-btn.pinpoint-4 {
		border-width: 3px;
		left: 317px;
	}

	.subtitle-heading {
		text-align: center;
	}

	.pinpoint-color-overlay {
		z-index: 0;
		background-image: linear-gradient(rgba(250, 248, 245, 0) 53%, rgba(216, 210, 188, 0.32) 53%);
		height: 84%;
	}

	.pinpoint-inner-content {
		order: -1;
		align-items: center;
		width: 100%;
		max-width: 600px;
		height: auto;
		margin-left: auto;
		margin-right: auto;
		padding: 4rem 0 3rem;
	}

	.home-section,
	.home-section.pinpoint-pain {
		overflow: hidden;
	}

	.pinpoint-text-wrapper._3 {
		left: 310px;
	}

	.pinpoint-text-wrapper._4 {
		left: 362px;
	}

	.pinpoint-text-wrapper._2 {
		top: 125px;
		left: 165px;
	}

	.pinpoint-text-wrapper._7,
	.pinpoint-text-wrapper._6,
	.pinpoint-text-wrapper._5 {
		left: 330px;
	}

	.section-container {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.section-container.pinpoint-pain {
		background-color: var(--white);
		padding-top: 2rem;
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.section-container.services-section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.section-container.patient-stories-section {
		padding: 0;
		overflow: hidden;
	}

	.service-heading {
		letter-spacing: 0.025em;
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.service-cb {
		width: 50%;
		height: auto;
	}

	.service-cb.top-cb {
		margin-bottom: 2rem;
	}

	.section-heading {
		display: block;
	}

	.service-row {
		grid-row-gap: 16px;
		flex-wrap: wrap;
		margin-bottom: 0;
	}

	.service-row._2 {
		margin-top: 1rem;
	}

	.quote-wrapper._2 {
		width: 100%;
	}

	.testimonial-img {
		width: 100%;
		height: 650px;
	}

	.testimonials-container {
		padding-top: 64px;
		padding-left: 64px;
		padding-right: 64px;
	}

	.testimonial-slide-wrapper {
		width: 75%;
		margin-right: 32px;
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.logofooter {
		background-position: 50% 0;
		justify-content: center;
		align-items: flex-start;
		display: flex;
	}

	.slide-2,
	.slide-3 {
		padding-top: 97px;
	}
}

@media screen and (max-width: 767px) {
	.patient-stories {
		margin-top: 0;
	}

	.hero-message {
		align-self: flex-end;
		width: 100%;
		height: auto;
		margin-top: auto;
		margin-left: 0;
		margin-right: auto;
		padding: 16px 40px 32px;
		top: auto;
	}

	.hero-heading {
		font-size: 30px;
		line-height: 35px;
	}

	.background-video {
		justify-content: flex-start;
		align-items: flex-end;
	}

	.tabs1_link-service.horizontal {
		object-fit: fill;
		justify-content: center;
		align-items: center;
		width: 47%;
	}

	.quick-links {
		grid-column-gap: 0%;
		grid-row-gap: 16px;
		flex-flow: wrap;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		align-content: stretch;
		justify-content: space-between;
		align-items: center;
		display: flex;
	}

	.form-container {
		border-bottom: 1px solid var(--primary-red);
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 32px;
	}

	.field-container {
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.doctor-search-tool {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		width: 100%;
		display: grid;
	}

	.advant__block {
		padding: 26px;
	}

	.advant__columns {
		flex-flow: column;
		grid-template-rows: auto auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: flex;
	}

	.advant__heading {
		height: 41px;
		line-height: 22px;
	}

	.tabs {
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
	}

	.tabs-menu {
		flex-flow: row;
		width: auto;
	}

	.tabs-content {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.tab {
		justify-content: center;
		align-items: center;
		padding-left: 16px;
		padding-right: 16px;
		font-size: 14px;
		display: flex;
	}

	.tabpane {
		height: auto;
	}

	.u-img-cover,
	.txtdiv {
		width: 100%;
	}

	.pane-content {
		flex-flow: column;
		margin-top: 16px;
	}

	.primary-btn.red.small {
		padding-left: 20px;
		padding-right: 20px;
	}

	.primary-btn.small {
		min-width: 120px;
		padding: 10px;
	}

	.secondary {
		margin-left: 0;
	}

	.footer-column-title {
		text-align: center;
	}

	.footer-address-column {
		width: 100%;
	}

	.footer-links-list {
		text-align: center;
		width: 100%;
		padding-left: 0;
	}

	.footer-content-row {
		flex-direction: column;
	}

	.footer-content-wrap {
		padding-left: 30px;
		padding-right: 30px;
	}

	.social-icons-wrap {
		justify-content: center;
		align-items: center;
		padding-top: 1em;
		padding-bottom: 1em;
		padding-right: 0;
	}

	.footer-list-link {
		white-space: nowrap;
	}

	.footer-list-link.special {
		align-self: center;
	}

	.footer-other-clinics-wrap {
		text-align: center;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		padding-left: 0;
	}

	.footer-column-wrap {
		flex-flow: column;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.footer-column-wrap.other-locations {
		justify-content: center;
		align-items: center;
		margin-top: 0;
	}

	.footer-column-wrap.other-locations.frame {
		border-top: 1px solid var(--secondary-gold);
		padding-top: 1.5rem;
	}

	.grip-logo-link {
		float: none;
		margin-bottom: 0;
	}

	.column-2 {
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-end;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.footer-signoff-link-wrap {
		float: none;
		display: inline-block;
	}

	.footer-copyright-2 {
		float: none;
	}

	.column-3 {
		justify-content: flex-start;
		align-items: flex-start;
		overflow: hidden;
	}

	.div-block {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
	}

	.footer-copyright-2-copy {
		float: none;
		text-align: center;
		max-width: none;
		margin-left: auto;
		margin-right: auto;
	}

	.footer-signup {
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin-left: 0;
		display: flex;
	}

	.div-block-3 {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		text-align: center;
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
	}

	.brand-logo {
		width: 255px;
		max-width: 215px;
		margin-top: 0;
	}

	.search-3 {
		padding-right: 0;
	}

	.menu-button {
		margin-right: 2rem;
	}

	.header__logo-link-copy {
		align-self: center;
		position: relative;
		top: 0;
	}

	.navbar {
		height: 85px;
	}

	.two-part-mobile-nav-wrapper.top {
		color: #b08d57;
	}

	.search-input-3 {
		padding-left: 0;
	}

	.search-button-icon {
		background-size: 30px;
		margin-left: auto;
	}

	.search-button-icon:hover {
		background-size: 30px;
	}

	.mobile-menu-arrow {
		width: 16%;
	}

	.exit-button-icon {
		background-size: 30px;
		margin-left: auto;
	}

	.exit-button-icon:hover {
		background-size: 30px;
	}

	.search-icon.desktop,
	.search-icon.mobile {
		margin-top: 0;
		margin-left: auto;
	}

	.main-nav-link-3.mobile-duplicate {
		width: 84%;
		padding-left: 2rem;
		transition-property: none;
	}

	.search-content {
		border-top: 1px solid rgba(91, 91, 91, 0.15);
		width: 100vw;
		margin-right: 0;
		padding-left: 2rem;
		padding-right: 2rem;
		display: flex;
		top: 0%;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.nav-menu {
		margin-top: 0;
	}

	.widthwrapper {
		height: 100%;
		padding-right: 0%;
	}

	.mobile-menu-arrow-4 {
		width: 16%;
	}

	.top-nav-div {
		padding-top: 0;
		display: none;
	}

	.search-form {
		float: right;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		justify-content: flex-end;
		width: 35px;
		transition: all 0.5s ease-in-out;
		overflow: hidden;
	}

	.search-button {
		z-index: 1;
		border-color: rgba(0, 0, 0, 0);
		height: 35px;
		position: relative;
	}

	.slide {
		width: 100%;
		padding: 40px;
	}

	.icon-11 {
		color: var(--white);
	}

	.paragraph-2,
	.paragraph-3,
	.paragraph-4,
	.paragraph-5 {
		text-align: center;
	}

	.play-pause-button {
		z-index: 5;
	}

	.right-sidebar-column {
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}

	.inside-page-header-wrap {
		padding-left: 0;
		padding-right: 0;
		display: none;
	}

	.block-quote-2 {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.h2-header-link {
		font-size: 1.75rem;
	}

	.content-container.threequarterwidth {
		padding-right: 0;
	}

	.quote-content {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.ordered-list-2 {
		padding-left: 20px;
	}

	.inside-content-beckground {
		padding-bottom: 1.5rem;
		padding-left: 30px;
		padding-right: 30px;
	}

	.intro-paragraph-2 {
		font-size: 1.125rem;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.section-wrap {
		padding-top: 80px;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.main-content-section {
		margin-right: 0;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.main-content-wrapper {
		padding-left: 0;
		padding-right: 0;
	}

	.h5-header-link {
		font-size: 1.125rem;
	}

	.inside-page-background-image {
		min-height: 80px;
	}

	.primary-btn-copy.red.small {
		padding-left: 20px;
		padding-right: 20px;
	}

	.primary-btn-copy.small {
		min-width: 120px;
		padding: 10px;
	}

	.inside-content-beckground-full {
		padding-bottom: 1.5rem;
		padding-left: 30px;
		padding-right: 30px;
	}

	.main-content-section-copy {
		margin-right: 0;
	}

	.tertiary {
		margin-left: 0;
	}

	.pinpoint-diagram {
		width: 470px;
		min-width: 470px;
		margin-top: 0;
		display: block;
	}

	.pinpoint-btn {
		border-width: 2px;
		width: 24px;
		height: 24px;
	}

	.pinpoint-btn.pinpoint-3 {
		left: 165px;
	}

	.pinpoint-btn.pinpoint-2 {
		left: 95px;
	}

	.pinpoint-btn.pinpoint-1 {
		left: 35px;
	}

	.pinpoint-btn.pinpoint-5,
	.pinpoint-btn.pinpoint-7,
	.pinpoint-btn.pinpoint-6 {
		left: 182px;
	}

	.pinpoint-btn.pinpoint-4 {
		left: 218px;
	}

	.subtitle-heading {
		text-align: center;
	}

	.subtitle-heading.testimonials-heading {
		margin-bottom: 1rem;
		line-height: 1.2;
	}

	.pinpoint-inner-content {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		padding-top: 0;
	}

	.pinpoint-text-wrapper._3 {
		left: 208px;
	}

	.pinpoint-text-wrapper._4 {
		left: 260px;
	}

	.pinpoint-text-wrapper._2 {
		left: 65px;
	}

	.pinpoint-text-wrapper._7,
	.pinpoint-text-wrapper._6,
	.pinpoint-text-wrapper._5 {
		left: 225px;
	}

	.section-container.pinpoint-pain {
		padding-left: 0;
		padding-right: 0;
	}

	.service-cb {
		width: 50%;
	}

	.service-row {
		grid-row-gap: 16px;
		flex-flow: wrap;
	}

	.service-row._2 {
		margin-top: 1rem;
	}

	.quote-text-wrapper {
		padding-bottom: 2rem;
	}

	.attribution,
	.attribution.bold {
		padding-left: 0;
	}

	.quote-text {
		margin-bottom: 1rem;
		padding-left: 0;
		padding-right: 2rem;
	}

	.testimonial-img {
		height: 300px;
	}

	.testimonial-right-slide-arrow {
		right: 35%;
	}

	.testimonial-left-slide-arrow {
		left: 35%;
		right: auto;
	}

	.testimonials-container {
		flex-direction: column;
		padding-top: 32px;
		padding-left: 32px;
		padding-right: 32px;
	}

	.testimonial-slide-wrapper {
		width: 100%;
		height: auto;
		margin-top: -48px;
		position: static;
	}

	.slide-2 {
		vertical-align: bottom;
		background-position: 50%;
	}

	.hero-area {
		justify-content: center;
		align-items: flex-end;
		display: flex;
	}
}

@media screen and (max-width: 479px) {
	.herovideosection {
		height: 100%;
	}

	.hero-message {
		justify-content: flex-end;
		align-items: center;
		height: auto;
		padding-bottom: 48px;
		padding-left: 16px;
		padding-right: 16px;
	}

	.hero-heading {
		text-align: center;
		max-width: 350px;
	}

	.background-video {
		justify-content: center;
		align-items: center;
	}

	.input-under-hero-section {
		padding-bottom: 32px;
	}

	.tabs1_link-service {
		width: 100%;
	}

	.tabs1_link-service.horizontal {
		flex-flow: column;
		height: 100px;
	}

	.iconwrap.horizontal.small {
		width: 35px;
		height: 32px;
	}

	.quick-links {
		grid-template-rows: auto auto;
	}

	.textwithicon {
		text-align: center;
	}

	.hero-section {
		height: 100vh;
	}

	.field-container {
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.dropdown-list.custom-scrollbar {
		border: 1px solid var(--secondary-gold);
		box-shadow: 5px -18px 0 8px rgba(0, 0, 0, 0.2);
	}

	.dropdown-list.custom-scrollbar.w--open {
		z-index: 2000;
	}

	.page-h3 {
		font-size: 24px;
	}

	.doctor-search-tool {
		flex-flow: column;
		grid-template-columns: 1fr;
	}

	.advant__block {
		justify-content: flex-start;
	}

	.advant__columns {
		grid-template-columns: 1fr;
	}

	.advant__img-wrap {
		margin-bottom: 16px;
	}

	.p {
		text-align: center;
	}

	h4.advant__heading {
		margin-bottom: 16px;
	}

	.page-h2 {
		font-size: 32px;
	}

	.tabs-menu {
		flex-flow: wrap;
		justify-content: center;
		align-items: center;
	}

	.tab {
		width: 135px;
	}

	.txtdiv {
		justify-content: flex-start;
		align-items: center;
	}

	.primary-btn.small {
		min-width: 110px;
	}

	.buttonwrapper {
		padding-left: 16px;
		padding-right: 16px;
	}

	.footer-column-title._2 {
		text-align: center;
	}

	.footer-address-column {
		flex-direction: column;
	}

	.footer-links-list-item {
		text-align: center;
		width: 100%;
	}

	.footer-links-list {
		align-self: center;
		display: flex;
	}

	.social-icons-wrap {
		float: none;
		flex-wrap: wrap;
		width: 100%;
		overflow: hidden;
	}

	.footer-list-link.special {
		white-space: normal;
		justify-content: center;
		align-items: flex-start;
		display: flex;
	}

	.footer-column-wrap {
		width: 100%;
		margin-bottom: 1.5em;
	}

	.footer-column-wrap.other-locations {
		flex-direction: column;
		margin-top: 0;
		margin-bottom: 0;
		display: flex;
	}

	.grip-logo-link {
		float: none;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		display: flex;
	}

	.column-2 {
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding-left: 0;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.signoff-div {
		padding-left: 32px;
		padding-right: 32px;
	}

	.footer-copyright-2 {
		text-align: center;
	}

	.column-3 {
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 0.5rem;
	}

	.columns-2 {
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
	}

	.brand-logo {
		width: 240px;
	}

	.nav-cover {
		z-index: -1;
	}

	.menu-button {
		margin-right: 1rem;
	}

	.header__logo-link-copy {
		margin-left: 16px;
	}

	.search-input-3 {
		letter-spacing: 0;
		padding-left: 0;
		font-size: 0.8rem;
	}

	.search-button-icon {
		background-size: 28px;
		border-radius: 0;
		margin-top: 0;
		padding-left: 15px;
		padding-right: 15px;
	}

	.search-button-icon:hover {
		background-size: 28px;
		border-radius: 20px;
	}

	.mobile-menu-arrow {
		width: 15%;
		position: relative;
	}

	.mobile-menu-arrow.white {
		justify-content: flex-end;
		align-items: center;
		width: 15%;
	}

	.exit-button-icon {
		background-size: 28px;
		border-radius: 0;
		margin-top: 0;
		padding-left: 15px;
		padding-right: 15px;
	}

	.exit-button-icon:hover {
		background-size: 28px;
		border-radius: 20px;
	}

	.search-icon {
		margin-left: 10px;
		margin-right: 10px;
	}

	.search-icon.desktop,
	.search-icon.mobile {
		margin-left: auto;
		margin-right: 0.75rem;
		display: block;
	}

	.main-nav-link-3.mobile-duplicate {
		width: 80%;
		padding-left: 1.5rem;
	}

	.main-nav-link-3.mobile-duplicate.white {
		width: 90%;
	}

	.search-button-2 {
		padding: 0.65rem 0;
		font-size: 0.8rem;
	}

	.search-content {
		border-top: 1px solid rgba(91, 91, 91, 0.15);
		align-items: center;
		width: 100vw;
		margin-left: 0%;
		margin-right: 0;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
	}

	.nav-menu {
		margin-top: -5px;
	}

	.mobile-menu-arrow-4 {
		width: 20%;
		position: relative;
	}

	.search-form-wrapper {
		height: 35px;
	}

	.search-form {
		width: 100%;
	}

	.search-button {
		z-index: 21;
	}

	.dropdown-2 {
		z-index: 101;
	}

	.dropdown-2.zetindex104 {
		z-index: 104;
	}

	.dropdown-2.zetindex103 {
		z-index: 103;
	}

	.div-block-5 {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
		padding-left: 0;
		padding-right: 0;
	}

	.mask {
		padding-top: 0;
	}

	.slide {
		padding-top: 0;
		padding-bottom: 0;
	}

	.navcomplete {
		position: relative;
	}

	.right-sidebar-column {
		margin-top: 0;
		padding-top: 0;
	}

	.current {
		font-size: 0.8rem;
	}

	.inside-pages-paragraph {
		font-size: 0.9rem;
	}

	.content-container.threequarterwidth {
		padding-left: 0;
		padding-right: 0;
	}

	.inside-content-beckground {
		padding-left: 20px;
		padding-right: 20px;
	}

	.intro-paragraph-2 {
		line-height: 1.5rem;
	}

	.button-styling {
		flex-wrap: wrap;
		padding-left: 0;
	}

	.paragraph-12 {
		font-size: 0.9rem;
	}

	.button {
		margin-bottom: 1rem;
	}

	.breadcrumb-link-1.breadcrumb-link-2 {
		font-size: 0.8rem;
	}

	.inner-image-content {
		background-position: 20%;
	}

	.inner-image-content.recent-blog-post {
		height: 250px;
	}

	.image-block {
		width: 100%;
		height: auto;
	}

	.image-block.inside-page {
		height: auto;
	}

	.heading-6 {
		font-size: 2.5rem;
		line-height: 3rem;
	}

	.breadcrumb-current.breadcrumb-link-2 {
		font-size: 0.8rem;
	}

	.primary-btn-copy.small {
		min-width: 110px;
	}

	.inside-content-beckground-full {
		padding-left: 20px;
		padding-right: 20px;
	}

	.pinpoint-diagram {
		width: 260px;
		min-width: 260px;
		height: 375px;
	}

	.text-block {
		font-size: 1rem;
		line-height: 1.55rem;
	}

	.text-block.left-justified {
		text-align: center;
		font-size: 1rem;
		line-height: 1.55rem;
	}

	.pinpoint-btn {
		width: 22px;
		height: 22px;
	}

	.pinpoint-btn.pinpoint-3 {
		width: 30px;
		height: 30px;
		top: 80px;
		left: 80px;
	}

	.pinpoint-btn.pinpoint-2 {
		width: 30px;
		height: 30px;
		top: 115px;
		left: 50px;
	}

	.pinpoint-btn.pinpoint-1 {
		width: 30px;
		height: 30px;
		top: 145px;
		left: 10px;
	}

	.pinpoint-btn.pinpoint-5 {
		width: 30px;
		height: 30px;
		top: 175px;
		left: 95px;
	}

	.pinpoint-btn.pinpoint-7 {
		width: 30px;
		height: 30px;
		top: 295px;
		left: 95px;
	}

	.pinpoint-btn.pinpoint-6 {
		width: 30px;
		height: 30px;
		top: 237px;
		left: 95px;
	}

	.pinpoint-btn.pinpoint-4 {
		width: 30px;
		height: 30px;
		top: 132px;
		left: 112px;
	}

	.subtitle-heading {
		text-align: center;
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.pinpoint-text {
		text-align: center;
		max-width: 70px;
		font-size: 0.75rem;
		line-height: 0.85rem;
	}

	.pinpoint-color-overlay {
		height: 82%;
	}

	.pinpoint-inner-content {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		align-items: center;
		padding-top: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.pinpoint-text-wrapper {
		padding: 0.25rem;
		top: 182px;
		left: -20px;
	}

	.pinpoint-text-wrapper._3 {
		padding: 0.25rem;
		top: 82px;
		left: 120px;
	}

	.pinpoint-text-wrapper._4 {
		align-items: center;
		padding: 0.25rem;
		top: 135px;
		left: 150px;
	}

	.pinpoint-text-wrapper._2 {
		padding: 0.025rem;
		top: 85px;
		left: 5px;
	}

	.pinpoint-text-wrapper._7 {
		padding: 0.25rem;
		top: 290px;
		left: 135px;
	}

	.pinpoint-text-wrapper._6 {
		padding: 0.25rem;
		top: 241px;
		left: 135px;
	}

	.pinpoint-text-wrapper._5 {
		padding: 0.25rem;
		top: 178px;
		left: 135px;
	}

	.section-container.pinpoint-pain {
		padding-top: 3rem;
	}

	.section-container.services-section {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.text-block-47 {
		font-size: 1rem;
		line-height: 1.55rem;
	}

	.service-cb {
		width: 100%;
		margin-bottom: 2rem;
	}

	.service-cb.lat-cb {
		margin-bottom: 0;
	}

	.service-cb.top-cb {
		width: 100%;
	}

	.section-heading {
		font-size: 1.85rem;
	}

	.service-row {
		margin-bottom: 0;
	}

	.service-row._2 {
		margin-top: 0;
	}

	.attribution,
	.attribution.bold {
		text-align: center;
		padding-bottom: 0.25rem;
		padding-left: 0;
		display: block;
	}

	.quote-text {
		text-align: center;
		padding-bottom: 0.25rem;
		padding-left: 0;
		padding-right: 0;
		font-size: 1rem;
		line-height: 1.55rem;
	}

	.quote-wrapper._2 {
		padding-top: 0.5rem;
		padding-bottom: 1.5rem;
	}

	.testimonial-img {
		height: 250px;
	}

	.testimonial-right-slide-arrow {
		right: 30%;
		bottom: 0;
	}

	.testimonial-left-slide-arrow {
		left: 30%;
		bottom: 0;
	}

	.testimonials-container {
		flex-wrap: wrap;
	}

	#testimonials_module_contentbox {
		height: 260px !important;
	}

	.testimonial-slide-wrapper {
		width: 100%;
		height: auto;
		padding: 3rem 2rem 6rem;
		position: static;
	}

	.text-block-48 {
		text-align: center;
	}

	.newssection {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
	}

	.body {
		background-position: top right;
	}
}

@media screen and (max-width: 767px) {
	#w-node-_0ea10a18-de78-fa73-c499-23f40b127b95-7079d16b {
		grid-area: span 1 / span 2 / span 1 / span 2;
	}
}

@media screen and (max-width: 479px) {
	#w-node-_0ea10a18-de78-fa73-c499-23f40b127b95-7079d16b {
		grid-area: span 1 / span 1 / span 1 / span 1;
	}
}


/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Webflow **********************/
/*****************************************************/
.w-webflow-badge {
	display: none !important;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background: #7e2a3d;
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: #faf8f5;
	padding: 0px 2px;
}

.custom-scrollbar {
	border-top-right-radius: 8px !important;
	border-bottom-right-radius: 8px !important;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 8px;
	padding: 0px 1px ;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #7e2a3d;
	border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: #faf8f5;
	padding: 0px 1px;
	border-radius: 0px 8px 8px 0px;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*********************** Header **********************/
/*****************************************************/
.header-container {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1000 !important;
}

.resources-link {
	text-decoration: none;
	border-bottom: 1px solid transparent;
	font-size: inherit;
	color: inherit;
	font-family: inherit;
	font-weight: inherit;
}

/***************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
}

.accesible-navigation-menu ul li {
	position: relative;
}

.accesible-navigation-menu ul li .dropdown-toggle {
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.accesible-navigation-menu ul li:last-child .dropdown-toggle {
	padding-right: 0px;
}

.accesible-navigation-menu li.services {
	position: static;
}

.accesible-navigation-menu li.hasChildren .container-wrapper {
	position: absolute;
	padding-top: 17px;
	width: 100%;
}

.accesible-navigation-menu li.services .container-wrapper {
	left: 0px;
	position: absolute;
	padding-top: 17px;
	width: 100%;
	display: none;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul {
	color: var(--black);
	background-color: #d8d2bc;
	min-width: 180px;
	padding: 4px 0 0;
	left: 10px;
	overflow: visible;
	box-shadow: 4px 10px 9px -9px rgba(0, 0, 0, 0.2);
	margin: 0px;
	display: none;
	list-style-type: none;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.accesible-navigation-menu li.services .container-wrapper .container-wrapper-inner {
	display: none;
	background-color: #d8d2bc;
	width: 100%;
	box-shadow: 4px 10px 9px -9px rgba(0, 0, 0, 0.2);
	text-align: center;
}

.accesible-navigation-menu li.services .container-wrapper .container-wrapper-inner ul {
	max-width: 1200px;
	width: 100%;
	padding: 30px 30px;
	margin: 0 auto;
	min-height: 200px;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: flex-start;
	height: 270px;
	justify-content: flex-start;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul li {
	padding: 0;
	width: 100%;
	position: relative;
	display: inline-block;
}

.accesible-navigation-menu li.services .container-wrapper .container-wrapper-inner ul li {
	width: 20%;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul a,
.accesible-navigation-menu li.services .container-wrapper .container-wrapper-inner ul a {
	color: var(--black);
	text-transform: capitalize;
	width: 100%;
	margin-right: 0;
	padding: 0.85rem 2rem 0.85rem 1.25rem;
	font-size: 15px;
	line-height: 1.1rem;
	text-decoration: none;
	transition: padding 0.2s, background-color 0.25s;
	display: inline-block;
	text-align: left;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul a:hover,
.accesible-navigation-menu li.hasChildren .container-wrapper ul a.w--current,
.accesible-navigation-menu li.services .container-wrapper .container-wrapper-inner ul a:hover {
	background-color: var(--secondary-gold);
	color: var(--black);
}

.accesible-navigation-menu li.services.open .container-wrapper,
.accesible-navigation-menu li.services:hover .container-wrapper,
.accesible-navigation-menu li.hasChildren.open .container-wrapper,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.services.open .container-wrapper,
.accesible-navigation-menu li.services:hover .container-wrapper {
	display: block;
}

.accesible-navigation-menu li.services.open .container-wrapper .container-wrapper-inner,
.accesible-navigation-menu li.services:hover .container-wrapper .container-wrapper-inner {
	display: inline-block;
}

.accesible-navigation-menu li.services.open .container-wrapper .container-wrapper-inner ul,
.accesible-navigation-menu li.services:hover .container-wrapper .container-wrapper-inner ul {
	display: flex;
	flex-direction: column;
}

.accesible-navigation-menu li.hasChildren.open .container-wrapper ul,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper ul {
	display: inline-block;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul li a {
	margin-bottom: 0px;
	margin-top: 0;
	padding-bottom: 10px;
	padding-right: 27px;
	padding-top: 10px;
}

.accesible-navigation-menu li.hasChildren.open .container-wrapper ul ul,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper ul ul,
.accesible-navigation-menu li.hasChildren .container-wrapper ul ul {
	display: none;
	position: absolute;
	left: 100%;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul ul.centerVertically {
	transform: translateY(-65%);
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul li.haschildren-2:hover ul {
	display: inline-block;
}

@media (max-width: 1165px) {
	.accesible-navigation-menu li.services .container-wrapper .container-wrapper-inner ul {
		height: 480px;
	}
}


@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mm-menu_position-top {
	height: 100%;
	max-height: 100%;
	padding-bottom: 80px;
	top: -2px;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #d8d2bc;
}

.mm-panels .mm-panel {
	padding-top: 2px;
}

.mm-listitem:after {
	left: 0px;
	border-color: transparent;
}

.mm-navbar {
	border-color: transparent;
}

.mm-wrapper_opened .w-nav-overlay {
	display: none !important;
}

.mobile-navigation-menu li a.nav-a {
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 20px 3rem;
	line-height: 1rem;
	font-size: 0.9rem;
	font-weight: 500;
	transition: color 0.2s;
	color: #000;
	font-family: Montserrat, sans-serif;
}

.mobile-navigation-menu li.red {
	background-color: var(--primary-red);
	color: var(--white);
}

.mobile-navigation-menu li.red:hover {
	background-color: var(--primary-red);
	color: var(--white);
}

.mobile-navigation-menu li.red a.nav-a {
	color: #fff;
}

.mobile-navigation-menu li.red .mm-btn_next:after {
	border-color: #fff;
}

.mobile-navigation-menu li.grey,
.mobile-navigation-menu li.grey:hover {
	background-color: var(--secondary-gold);
}

.mm-navbar__title > span {
	text-transform: uppercase;
	line-height: 1rem;
	font-size: 0.9rem;
	font-weight: 500;
	transition: color 0.2s;
	color: #000;
	font-family: Montserrat, sans-serif;
	text-decoration: none;
	padding-top: 3px;
}

.mobile-navigation-menu li:hover,
.mobile-navigation-menu li.active {
	border-left: 4px solid var(--secondary-gold);
	background-color: rgba(179, 170, 126, 0.33);
}

.mm-listitem__btn {
	background-color: transparent;
	border-color: transparent;
	padding-right: 80px;
}

.mm-navbar__btn {
	background-color: transparent;
}

.mm-btn_next:after {
	border-color: #222;
	border-width: 1.5px;
}

.mm-btn_prev:before {
	border-color: #222;
	border-width: 1.5px;
	top: 2px;
}

.mm-btn_next:after {
	right: 36px;
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-listitem_selected > .mm-listitem__text {
	background: transparent;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
	margin-bottom: 15px;
}

.cbtext p:last-child {
	margin-bottom: 0px;
}

.slider-text p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

.slider-text p:last-child {
	margin-bottom: 0px;
}

.cbtext a {
	color: #000;
	border-bottom: 1px solid transparent;
}

.cbtext a:hover {
	text-decoration: none;
}

.tabs1_link-service {
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.dropdown-2.location {
	z-index: 100000;
}

.advant__block .newsdate {
	text-transform: none;
	margin: 0px;
	font-size: 15px !important;
}

h4.advant__heading {
	color: #000;
	font-family: Outfit, sans-serif;
	font-size: 18px;
	line-height: 24px;
}

h2.page-h3 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 30px;
	font-weight: bold;
	line-height: 1.2em;
	color: #000;
}

#news_module.homepage_contentbox .advant__col {
	opacity: 0;
}

#news_module.homepage_contentbox .p.mod--advant {
	margin-bottom: 10px;
	flex-grow: 1;
}

#news_module.homepage_contentbox .advant__heading {
	height: auto;
}

h3.page-h3 {
	color: #000;
}

.section-heading-wrapper h2 {
	color: #696864;
	text-align: center;
	max-width: 800px;
	margin-top: 0;
	margin-bottom: 1.5rem;
	font-size: 2rem;
	font-weight: 400;
	line-height: 2rem;
}

#news_module.homepage_contentbox .secondary-btn {
	position: relative !important;
	color: var(--primary-red) !important;
	align-items: center !important;
	min-height: 50px !important;
	margin-left: -10px !important;
	font-weight: 700 !important;
	text-decoration: none !important;
	transition: color 0.2s !important;
	display: flex !important;
}

#testimonials_module_contentbox {
	height: 240px;
	display: block;
}

#testimonials_module_contentbox .quote-wrapper {
	display: block;
}

#testimonials_module_contentbox .w-slide {
	height: auto;
}

@media screen and (max-width: 767px) {
	#news_module.homepage_contentbox .advant__columns .advant__col:last-child {
		grid-area: span 1 / span 2 / span 1 / span 2;
	}

	.tabs-content .u-img-cover {
		height: 60vw;
	}
}

@media screen and (max-width: 479px) {
	h2.page-h3 {
		font-size: 24px;
	}

	#news_module.homepage_contentbox .advant__columns {
		display: block;
	}

	#news_module.homepage_contentbox .advant__col {
		margin-bottom: 16px;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text {
	margin-bottom: 20px;
}

.slider-text p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	margin-bottom: 20px;
}

.slider-image {
	height: 100%;
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-side-article {
	position: absolute;
	background: #fefefe;
	top: auto;
	width: 100%;
	padding: 20px;
	bottom: 0;
}

.home-content-box-learn-more {
	color: #000;
}

.slider-side-article-title-span {
	font-size: 1.3rem;
}

.herovideosection {
	position: relative;
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child a:hover,
.breadcrumb-list li:last-child a {
	color: var(--primary-red);
	text-transform: capitalize;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0);
	text-decoration: none;
}

.breadcrumb-list li:last-child a:hover {
	text-decoration: underline;
}

.left-nav-list-link.active {
	background-color: var(--creme-white);
	color: var(--primary-red);
	border-radius: 25px;
	text-decoration: none;
}

.left-nav-nested-list-link.grandchild-nav-link.active {
	color: var(--primary-red);
	background-color: rgba(216, 210, 188, 0.3);
	border-radius: 20px;
}

.section-wrap.only-right .right-sidebar-column {
	width: calc(35% - 67px);
	padding: 0;
	font-size: 16px;
	font-family: Outfit, sans-serif;
}

.section-wrap.only-right .main-content-column-nested-2 {
	width: calc(65% + 67px);
}

.section-wrap.no-columns .inside-content-beckground {
	max-width: 1000px;
	padding-left: 32px;
	padding-right: 32px;
	min-height: 600px;
}

@media screen and (max-width: 991px) {
	.section-wrap.only-right .main-content-column-nested-2,
	.section-wrap.only-right .right-sidebar-column {
		width: 100%;
	}

	.section-wrap.no-columns .inside-content-beckground {
		padding-left: 64px;
		padding-right: 63px;
	}
}

@media screen and (max-width: 767px) {
	.section-wrap.no-columns .inside-content-beckground {
		padding-left: 32px;
		padding-right: 32px;
	}
}

@media screen and (max-width: 479px) {
	.section-wrap.no-columns .inside-content-beckground {
		padding-left: 20px;
		padding-right: 20px;
	}
}

iframe {
	max-width: 100%;
}

/************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-column td img {
	max-width: none;
}

.right-sidebar-column .box {
	border-top: 1px rgba(0, 0, 0, 0.15);
	margin-left: 0rem;
	margin-bottom: 15px;
	line-height: 1.35rem;
}

.right-sidebar-column .box p {
	color: var(--black);
	font-family: Montserrat, sans-serif;
	font-size: 16px;
}

.right-sidebar-column .box h4,
.right-sidebar-column .box h3 {
	color: var(--primary-red);
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 700;
	line-height: 20px;
	font-size: 18px;
}

.right-sidebar-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box.tools td:first-child img {
	margin-right: 5px;
}

.right-sidebar-column a {
	color: #333;
	border: 0 rgba(0, 0, 0, 0);
	border-bottom: 1px solid transparent;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.2s;
}

.right-sidebar-column .box.documents a {
	text-decoration: underline;
}

.right-sidebar-column .box.documents a:hover {
	text-decoration: none;
}

.right-sidebar-column a:hover {
	color: var(--primary-red);
	text-decoration: none;
}

.right-sidebar-column iframe {
	max-width: 100%;
	height: auto;
}

/*************** Left Sidebar ********************/
/************************************************/
.left-sidebar .box {
	background-color: var(--primary-red);
	border-radius: 25px;
	height: 100%;
	margin-right: 32px;
	padding: 32px;
	font-size: 16px;
	margin-bottom: 20px;
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
}

.left-sidebar .box,
.left-sidebar .box p {
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
}

.left-sidebar .box h2 {
	color: #fff;
	margin-bottom: 0.5rem;
	margin-top: 0;
	font-weight: 700;
	line-height: 20px;
	font-size: 18px;
	font-family: Outfit, sans-serif;
}

.left-sidebar iframe {
	max-width: 100%;
	height: auto;
}

.left-sidebar .box a {
	color: var(--white);
}

.left-sidebar .box a:hover {
	color: var(--black);
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.main-content-section a {
	color: var(--black);
	text-decoration: underline;
}

.main-content-section a:hover {
	color: var(--primary-red);
	text-decoration: none;
}

h1 {
	color: var(--primary-red);
	background-color: rgba(0, 0, 0, 0);
	margin-top: 0;
	margin-bottom: 1.5rem;
	padding: 0;
	line-height: 3rem;
	font-size: 2.75rem;
}

h2 {
	margin-bottom: 5px;
	font-size: 32px;
	font-weight: 700;
}

h3 {
	color: var(--primary-red);
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: 700;
}

h4 {
	color: var(--primary-red);
	margin-bottom: 5px;
	font-size: 22px;
	font-weight: 700;
}

h5 {
	color: var(--primary-red);
	text-transform: capitalize;
	margin-bottom: 5px;
	font-size: 18px;
	font-weight: 700;
}

h6 {
	color: var(--primary-red);
	text-transform: capitalize;
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: 700;
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 2.5rem;
		line-height: 3rem;
	}
}

h1 a {
	color: var(--primary-red) !important;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: inherit;
	font-weight: 400;
	line-height: inherit;
	text-decoration: none !important;
	transition: all 0.2s;
}


h2 a {
	color: var(--primary-red) !important;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 2rem;
	font-weight: 400;
	line-height: 2.25rem;
	text-decoration: none !important;
	transition: all 0.2s;
}

h3 a {
	color: var(--primary-red) !important;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	text-decoration: none !important;
	transition: all 0.2s;
}

h4 a {
	color: var(--primary-red) !important;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 22px;
	font-weight: 400;
	line-height: 24px;
	text-decoration: none !important;
	transition: all 0.2s;
}

h5 a {
	color: var(--primary-red) !important;
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 18px;
	font-weight: 400;
	line-height: 20px;
	text-decoration: none !important;
	transition: all 0.2s;
}

h6 a {
	color: var(--primary-red) !important;
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	text-decoration: none !important;
	transition: all 0.2s;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: var(--black) !important;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.main-content-section p {
	margin-bottom: 1rem;
	padding-bottom: 0;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.main-content-section li {
	margin-bottom: 0;
	padding-bottom: 0;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	h2 a {
		font-size: 1.75rem;
	}

	h3 a {
		font-size: 1.5rem;
	}

	h4 a {
		font-size: 1.25rem;
	}

	h5 a {
		font-size: 1.125rem;
	}
}

@media screen and (max-width: 479px) {
	.main-content-section p,
	.main-content-section li,
	.module-members {
		font-size: 0.9rem;
	}
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	min-width: 150px;
	margin-left: 0;
	margin-right: 0;
	padding: 16px;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	border-radius: 50px;
	color: var(--white) !important;
	background-color: var(--primary-red) !important;
	text-transform: capitalize;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
	text-align: center;
	font-family: Open Sans, sans-serif;
	min-height: auto;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	background-color: #460010 !important;
	color: var(--white) !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	min-width: 150px;
	padding: 16px;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	border-radius: 50px;
	color: #000000 !important;
	background-color: #d8d2bc !important;
	text-transform: capitalize;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
	text-align: center;
	min-height: auto;
	font-family: Open Sans, sans-serif;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	background-color: #b3aa7e !important;
	color: #000 !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	color: #7e2a3d !important;
	cursor: pointer;
	font-family: Open Sans, sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	text-decoration: none !important;
	transition: opacity 0.2s ease-in;
	background-color: #fff !important;
	border-bottom: 0px;
	background-image: url('/assets/images/arrow-narrow-right-svgrepo-com.png');
	background-position: calc(100% - 33px) center;
	background-repeat: no-repeat;
	background-size: 20px;
	padding-right: 60px;
	transition: all 300ms ease;
	min-height: auto;
	min-height: auto;
	position: static;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #7e2a3d !important;
	background-color: #fff !important;
	text-decoration: underline !important;
	background-position: calc(100% - 25px) center;
}

/* messages */
#message.success {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

#message.error {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.success p {
	color: #000;
	font-weight: 400;
}

#message.error p {
	color: #000;
	font-weight: 400;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
	font-family: Montserrat, sans-serif;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: black;
	opacity: 100%;
	color: white;
	font-weight: normal;
	font-family: Montserrat, sans-serif;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #7e2a3d;
	opacity: 100%;
	color: white;
	font-weight: normal;
	font-family: Montserrat, sans-serif;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	font-family: Montserrat, sans-serif;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #d8d2bc;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.main-content-column input[type="text"],
.main-content-column input[type="tel"],
.main-content-column input[type="password"],
.main-content-column input[type="email"],
.main-content-column select,
.main-content-column textarea,
.main-content-column input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	background-color: #fff;
	font-size: 0.875rem;
	margin-bottom: 10px;
}

.main-content-column textarea {
	resize: vertical;
}

.main-content-column label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.main-content-column input[type="text"],
	.main-content-column input[type="tel"],
	.main-content-column input[type="password"],
	.main-content-column input[type="email"],
	.main-content-column select,
	.main-content-column textarea,
	.main-content-column input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	background-color: rgba(216, 210, 188, 0.72);
	background-image: url('/20181016082423/assets/images/gradient-gold---20opacity-reflected.svg');
	background-position: 100% 0;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: 32px;
	margin-bottom: 32px;
	padding: 32px;
	font-weight: 400;
	font-size: 1.3rem;
	font-style: italic;
	line-height: 1.75rem;
}

blockquote p {
	font-size: inherit !important;
	line-height: inherit !important;
	font-family: inherit !important;
	color: inherit !important;
	font-weight: inherit !important;
}

blockquote p:last-child {
	margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

@media screen and (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

.pagination-wrapper > * {
	line-height: 20px;
}

/********************** Footer **********************/
/****************************************************/
.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
	color: #000;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#GRIPFooterLogo {
	opacity: 0.66;
	padding-top: 0px !important;
	line-height: 11px;
}

@media screen and (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields {
	padding: 0 0 0 0;
}

.ui-form-container .ui-form-label {
	left: -210px;
	width: 200px;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
.login-row__login.have-account-column {
	width: 550px;
}

.login-row__login #account_module.form .form_item .label,
.login-row__login #account_module.form .form_item.required .label {
	width: auto;
}

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (min-width: 992px) {
	#account_module.form .required_message {
		margin-right: 120px;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* submission forms */
#submissionforms_module {
	text-align: left;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 0;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

#submissionforms_module.cms_form .cms_steps_wrapper {
	padding-bottom: 30px;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	margin-right: 30px;
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_steps_wrapper {
		padding-bottom: 20px;
	}
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

#events_module #fullcalendar-controls #categories label {
	cursor: pointer;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faqs */
#faqs_module .cms_title h2 {
	margin-top: 10px;
}

#faqs_module .cms_title h2 a {
	font-size: 1.8rem;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(92, 92, 92, 0.15);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 1.25rem;
	margin-top: 10px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 1rem;
}

#jobs_module.cms_entity .quaternary_title {
	font-size: 1.7rem;
}

#jobs_module .filter-container input.button {
	margin-top: 0;
	padding: 12px 16px;
	line-height: 16px;
	margin-right: 10px;
}

.right-sidebar-column .box #frmDepartments .primary {
	margin-top: 20px;
}

#jobs_module.cms_form .cms_field label {
	cursor: pointer;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list article h2 {
	margin-bottom: 17px;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* newsletter */
#newsletters_module.entity ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#newsletters_module.entity h3,
#newsletters_module.entity h4 {
	margin-top: 0px;
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

#newsletters_module.list .item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0px 1.5rem 0px;
}

#newsletters_module.form .form_item .field label {
	cursor: pointer;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	background-color: #666;
	font-family: inherit;
	color: #ffffff;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* posts */
.posts-node .posts-comments .required_information {
	color: #ee0000;
	font-style: italic;
}

.posts-collection > .node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-collection .posts-node .posts-field h2 a {
	font-size: 1.8rem;
}

.posts-node #post-comments .post-comments-title {
	background-color: #000;
	margin-top: 20px;
	font-weight: normal;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	background-color: #fff;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.right-sidebar-column .box.posts-blocks.posts-blocks-rss-feeds a,
.right-sidebar-column .box.posts-blocks.posts-blocks-subscribes a {
	border-bottom: 0px;
}

.right-sidebar-column .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-sidebar-column .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-sidebar-column .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-sidebar-column .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-sidebar-column .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.right-sidebar-column .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 8px 10px 7px 10px !important;
	background-color: #7e2a3d !important;
	min-width: auto;
}

.right-sidebar-column .posts-blocks-date-filter-button.primary {
	padding: 10px 12px;
	margin-top: 10px;
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* videos */
#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}

#video_module.cms_list_carousel .video-container .video-file-youtube iframe,
#video_module.cms_list_carousel .video-container .video-file .cms_media_container,
#video_module.cms_list_carousel .video-container .video-file video {
	background-color: #000;
	font-family: inherit;
	color: #fff;
}

#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev span,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next span {
	background-color: #fff;
	font-family: inherit;
	color: #000;
}

#video_module.cms_entity .cms_footer {
	padding-top: 40px;
}

#video_module.cms_list {
	margin-bottom: 30px;
}

/* resources */
#moduleDocuments_module.cms_list .cms_category_icon_title_row {
	margin-bottom: 1.3rem;
}

#moduleDocuments_module.cms_list .cms_category_icon_title_row .cms_title {
	font-size: 28px;
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#staffdirectory_module.cms_list .cms_list_item .title_header {
	font-size: 1.8rem;
}


@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

@media (max-width: 767px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: center;
	}
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/**************** Medical Modules *****************/
/*************************************************/
#physicians-module.advanced-search .physicians-advanced-search-form-row .form-field-label-with-tooltip.provider-name {
	border-top: 1px solid #d1d1d1;
}

#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-left h2 {
	font-size: 1.5em !important;
	white-space: nowrap;
}

#physicians-module.advanced-search h3 .physicians-advanced-search-section-title-number {
	background-color: #666 !important;
	border: 1px solid rgba(77, 77, 77, 0.25) !important;
	border-radius: 0.125em !important;
	color: #fff !important;
	padding-left: 0.5em !important;
	padding-right: 0.5em !important;
	padding-top: 0.1em;
	padding-bottom: 0.1em;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap .physicians-search-form-rows-wrap {
	background-color: hsla(0, 0%, 82%, 0.1);
	border-color: #d1d1d1;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap a {
	font-weight: normal;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap .form-field-label.physicians-advanced-search-label {
	font-weight: bold;
	font-size: 1em;
	margin-bottom: 0.15em;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap .form-field.physicians-advanced-search-field {
	background-image: linear-gradient(white 0%, #f3f3f3 100%);
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap.name-search-wrap .select2-container--default .select2-selection--single {
	border: 1px solid #cccccc;
	height: auto;
	margin-top: 10px;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap.name-search-wrap .select2-container--default .select2-selection--single .select2-selection__rendered {
	font-size: 0.875em;
	line-height: 1.42857;
	padding-top: 17px;
	padding-bottom: 17px;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap.name-search-wrap .physicians-search-form-submit.provider-submit-button {
	margin-top: 15px;
	margin-bottom: 0px;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap.name-search-wrap .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 72px;
}

#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-right .button.physicians-view-all {
	text-align: center !important;
	margin-bottom: 0.75em !important;
	padding: 0.75em 0 !important;
}

.physicians-advanced-search-page-header {
	display: flex;
}

#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-left {
	width: 59.5% !important;
	padding-right: 3.5em !important;
}

#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-right {
	align-self: flex-end;
	padding-left: 2em !important;
	width: 40.5% !important;
	border-left: 1px solid #d1d1d1;
}

.physician-search-accordion.in-main-content-area {
	background-color: hsla(0, 0%, 82%, 0.25) !important;
	border-color: #d1d1d1 !important;
	padding-right: 0.45em !important;
}

.physician-search-accordion.in-main-content-area .search-title {
	color: #4d4d4d;
	cursor: pointer;
	font-size: 1.563em;
	margin-bottom: 0.5em;
	margin-top: 0.5em;
}

.physician-search-accordion.in-main-content-area .search-reveal-trigger {
	background-color: transparent;
	border: none;
}

.md-custom-heading.md-medium-heading.phone-list-view,
.md-custom-heading.md-medium-heading.fax-list-view {
	background-image: none !important;
}

.md-physician-profile-top .md-list-item a,
.md-physician-profile-top .md-column-content-wrap div a {
	font-weight: normal;
}

#physicians-module.physicians-collection .physician-image {
	text-align: left !important;
}

#physicians-module.physicians-collection .node {
	border-bottom: 1px solid #d1d1d1 !important;
}

.physician-search-accordion .physician-search-wrap .physician-search-field {
	border-top: 1px solid #e1e3e0;
}

.physician-search-accordion .physician-search-wrap .physician-search-field label {
	font-weight: bold;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .form-reset {
	font-weight: normal;
	font-size: 0.9rem;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .form-reset:hover {
	color: #690100;
	cursor: pointer;
}

.physician-search-accordion .form-reveal-wrap {
	padding-right: 5px;
}

.physician-search-accordion .form-reveal-wrap .search-form-section-heading .title-span {
	background-color: #666;
	border-color: rgba(77, 77, 77, 0.25);
	border-radius: 0.125em;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	display: inline-block;
	margin-right: 0.25em;
	padding-left: 0.5em;
	padding-right: 0.5em;
	padding-top: 0.1em;
	padding-bottom: 0.15em;
}

.physician-search-accordion .form-reveal-wrap .select2-container--default .select2-selection--single .select2-selection__rendered {
	font-size: 0.875em;
}

.physician-search-accordion .form-reveal-wrap .select2-container--default .select2-selection--single {
	background: #fff;
}

.physician-search-accordion .form-reveal-wrap .physician-search-field .bottom-search-submit {
	color: transparent !important;
	min-width: 15% !important;
	height: 2.35rem;
}

.physician-profile-top .md-list-item a,
.md-list-item a,
.physician-profile-top .md-column-content-wrap a {
	font-weight: normal;
}

.physician-profile-section h2.medical-section {
	font-size: 1.5em;
	margin-bottom: 0.75em;
	margin-top: 0.25em;
}

#physicians-module.node .doctor-profile-section h2.md-section-h2 {
	margin-top: 0.7em;
}

.md-custom-heading a {
	color: #464646;
	border-bottom: 0px;
}

#physicians-module .doctor-profile-section .md-row.md-patient-comment-block {
	background-color: hsla(0, 0%, 82%, 0.25);
	font-family: Roboto, sans-serif;
}

#physicians-module.node .physician-profile-section .md-row {
	margin-bottom: 0.75em;
}

#physicians-module.node .md-custom-heading.patient-rating .md-patient-rating-stars-wrap {
	margin-bottom: 0.75em;
	margin-top: 0px;
}

#physicians-module.node .icon-phone svg,
#physicians-module.node .icon-fax svg {
	width: 1em;
	height: 1em;
}

#physicians-module.node .icon-phone .phone-text,
#physicians-module.node .icon-fax .fax-text {
	display: inline;
}

#physicians-module.node .icon-phone a.phone-link,
#physicians-module.node .icon-fax a.fax {
	display: none;
}

#physicians-module.node .md-patient-rating-card:nth-child(3n) {
	margin-right: 0px;
}

#physicians-module.node .md-patient-rating-card {
	margin-right: 1.9%;
	background-color: hsla(0, 0%, 82%, 0.25);
}

#physicians-module.node .md-row.md-patient-rating-row {
	justify-content: flex-start;
	-webkit-justify-content: flex-start;
	-ms-justify-content: flex-start;
}

#physicians-module.node .md-patient-rating-card .md-patient-rating-stars-wrap {
	margin-bottom: 0px;
}

#physicians-module.node .md-patient-rating-card-score-wrap {
	display: flex;
}

.physician-search-accordion .form-reveal-wrap .criteria-search-form .physician-search-field.button-section-wrap .top-search-submit {
	width: auto;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .form-reset {
	float: right;
}

#physicians-module.node .md-patient-rating-card {
	max-width: 32%;
	width: 32%;
}

#physicians-module.node .md-column._50.side-column.field.field--phone,
#physicians-module.node .md-column._50.side-column.field.field--fax {
	width: 100%;
}

#physicians-module.node .md-column._70 .md-row {
	overflow: hidden;
}

#physicians-module .md-custom-heading.md-big-heading.icon-telehealth svg,
#physicians-module .md-custom-heading.md-medium-heading.telehealth-list-view {
	width: 100px;
}

#physicians-module.node.contemporary .md-physicians-subtitle {
	font-size: 28px !important;
	line-height: 32px !important;
}

#physicians-module .md-custom-heading {
	font-size: 1rem !important;
}

#physicians-module.node.contemporary .md-physicians-profile .md-physicians-profile-container .md-physicians-profile-details .md-physicians-profile-specialties ul li {
	font-size: 1rem !important;
	display: inline-block;
	margin-right: 10px;
}

#physicians-module.node.contemporary .md-anchors-container .md-anchor-link a {
	border-bottom: 1px solid transparent;
	text-decoration: none !important;
	background-color: #7e2a3d !important;
}

#physicians-module.node.contemporary .md-physicians-profile .md-physicians-profile-container .md-physicians-profile-details .md-physician-accepting-patients-container .md-custom-heading {
	font-size: 16px !important;
}

#physicians-module.node.contemporary .md-physicians-subtitle-h3 a {
	border-bottom: 1px solid transparent !important;
}

#physicians-module.node.contemporary .md-physicians-profile .md-physicians-profile-container .md-physicians-profile-details .md-column._50.side-column {
	min-width: 150px;
}

#physicians-module.node.contemporary .md-physicians-testimonials .md-testimonial .md-patient-comment {
	padding-top: 0px !important;
	background: none !important;
}

#physicians-module.collection.physicians-collection.contemporary .md-list {
	list-style-type: none;
}

#clinics-module .md-location-list-h2 {
	font-size: 1.25em;
	margin-bottom: 0.75em;
	margin-top: 0;
}

.module-medical-dependencies-physicians .physicians-blocks-search .title h4 {
	color: #fff;
	margin-bottom: 1rem;
}

.module-medical-dependencies-physicians .physicians-blocks-search .primary {
	background-color: #d8d2bc !important;
	color: #000 !important;
}

#clinics-module .md-section-h2 {
	font-size: 1.5rem !important;
	margin-bottom: 0.75em !important;
	margin-top: 0.25em !important;
}

.md-specialties-right-column-content-wrap {
	border-left: 0px;
	padding-left: 0px;
}

#clinics-module .facility-maps .md-list-item .link.document-link {
	font-weight: bold;
	padding-left: 1.6rem;
}

#clinics-module .md-column-content-wrap .clinic-image {
	margin-bottom: 0.4rem;
}

#clinics-module .md-big-heading.icon-phone svg,
#clinics-module .md-big-heading.icon-fax svg {
	height: 1.4rem;
	width: 1.4rem;
	top: 4px;
}

#specialties-module.specialties-collection {
	margin-left: -10px;
	margin-right: -10px;
}

#specialties-module.specialties-collection h2 {
	margin-top: 0.25em;
}

#specialties-module.specialties-collection .w-col-8.w-col-stack,
#specialties-module.specialties-collection .md-specialties-right-column {
	padding-left: 10px;
	padding-right: 10px;
}

#specialties-module.specialties-collection .md-specialties-right-column-content-wrap {
	border-left: 1px solid #d1d1d1;
}

#physicians-module.node .side-column .md-patient-rating-stars-wrap {
	margin: -2px 5px 12px 0;
}

#specialties-module.specialty-node h2 {
	margin-top: 0.125em;
	font-size: 1.875em;
}

#specialties-module.specialties-collection .md-location-list-h2 {
	font-size: 1.25em;
	margin-bottom: 0.75em;
	margin-top: 0;
}

#specialties-module.specialties-collection .md-location-list-h2 a {
	border-bottom: 1px solid rgba(84, 114, 194, 0.25);
	color: #4b79b3;
	font-weight: 700;
}

#specialties-module.specialties-collection {
	margin-left: 0px;
	margin-right: 0px;
}

#specialties-module.specialty-node .faq-collection h2 {
	margin-bottom: 0.25em;
}

#specialties-module.specialties-collection.resources-collection .md-resources-item-summary {
	margin-top: 0px;
}

#specialties-module.resources-collection .md-resources-link-title.flex-link-title {
	font-size: 1.25em;
	margin-bottom: 0.125em;
	margin-top: 0;
	color: #4b79b3;
	font-weight: 700;
}

#specialties-module.resources-collection .md-resources-link-title.flex-link-title a {
	color: #4b79b3;
	font-weight: 700;
}

#specialties-module.resources-collection .md-resources-link-title.flex-link-title a:hover {
	border-bottom-color: #d6c59d;
	color: #690100;
}

#specialties-module.resources-collection .md-column._10.file-type.flex-file-type {
	width: 10%;
}

#specialties-module.resources-collection .md-resources-icon-image {
	max-width: 90%;
}

#physicians-module.advanced-search .primary.md-button {
	font-weight: 500;
	line-height: inherit;
}

#physicians-module.advanced-search .physicians-advanced-search-header-split h2 {
	margin: 0px !important;
	font-size: 0px !important;
	line-height: 0px !important;
}

.physician-search-accordion .physician-search-field .top-search-submit {
	height: auto !important;
	padding: 10px !important;
}

#specialties-module.specialty-node.contemporary .md-anchors-container .md-anchor-link a {
	background-color: #7e2a3d !important;
	text-decoration: none !important;
	border-bottom: 1px solid transparent;
}

.left-sidebar .md-faceted-search-container .select2-container .select2-selection--single .select2-selection__rendered {
	max-width: 200px;
	width: 13vw;
	min-width: 130px;
}

#specialties-module.specialties-collection.contemporary .md-row .md-specialties-letter {
	background-color: #7e2a3d !important;
}

.module-medical-dependencies-physicians .md-faceted-search-container .faceted-search-form .form-field .tooltip {
	background-color: #fff !important;
}

@media (max-width: 991px) {
	.md-custom-heading.md-medium-heading.phone-list-view,
	.md-custom-heading.md-medium-heading.fax-list-view {
		padding-left: 0px !important;
	}

	#specialties-module.specialties-collection .md-specialties-right-column-content-wrap {
		border-left: 0px;
		border-top: 1px solid #d1d1d1;
	}

	#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-left {
		padding-right: 1.5em !important;
		width: 55% !important;
	}

	#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-right {
		width: 45% !important;
	}

	#specialties-module.specialty-node h2 {
		font-size: 1.625em;
	}

	#specialties-module.resources-collection .md-column._10.file-type.flex-file-type {
		text-align: center;
	}


	#physicians-module.node .md-patient-comments-show-more-wrap {
		align-items: center;
		display: flex;
		flex-direction: row;
		font-weight: 700;
		justify-content: space-between;
	}

	#physicians-module.node .icon-phone .phone-text,
	#physicians-module.node .icon-fax .fax-text {
		display: none;
	}

	#physicians-module.node .icon-phone a.phone-link,
	#physicians-module.node .icon-fax a.fax-link {
		display: inline;
	}

	#physicians-module.node .button.md-show-more-comments {
		order: -1;
	}

	.md-list.services-list {
		-moz-column-count: 2;
	}


	#physicians-module.node.contemporary .md-column.address {
		margin-bottom: 10px;
	}
}

@media (max-width: 767px) {
	.physician-search-accordion .form-reveal-wrap .criteria-search-form .physician-search-field.button-section-wrap {
		padding-top: 35px;
		border-top: 1px solid rgba(84, 159, 196, 0.25);
	}

	#clinics-module.clinics-collection .locations-column-1.md-column-content-wrap {
		margin-bottom: 0em;
		margin-right: 10px;
		padding-bottom: 0em;
	}

	#clinics-module .md-section-h2 {
		font-size: 1.25em !important;
	}

	#physicians-module.advanced-search .physicians-advanced-search-form-wrap .form-field-label.physicians-advanced-search-label {
		font-size: 1.125em;
	}

	#physicians-module.advanced-search .physicians-advanced-search-form-wrap.name-search-wrap .select2-container--default .select2-selection--single {
		margin-top: 1px;
	}

	#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-right .button.physicians-view-all {
		display: block;
		margin-bottom: 1.25em;
		margin-left: auto;
		margin-right: auto;
		max-width: 75%;
		padding-bottom: 1em;
		padding-top: 1em;
	}

	#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-left h2 {
		font-size: 1.25em !important;
	}

	#physicians-module.advanced-search .physicians-advanced-search-form-wrap.name-search-wrap .select2-container--default .select2-selection--single .select2-selection__arrow {
		height: 52px;
	}

	.physicians-advanced-search-page-header {
		display: block;
	}

	#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-left {
		padding-right: 0 !important;
		width: 100% !important;
	}

	#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-right {
		width: 100% !important;
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-right .button.physicians-view-all {
		line-height: 1.375rem !important;
		padding-bottom: 1em !important;
		padding-top: 1.05em !important;
		margin-bottom: 1.25em !important;
	}

	#physicians-module.node .md-patient-rating-card-score-wrap {
		display: block;
	}

	.physician-profile-section h2.medical-section {
		font-size: 1.25em;
	}

	#specialties-module.specialty-node h2 {
		font-size: 1.5em;
	}

	.physician-search-accordion .physician-search-wrap .physician-search-field .form-reset {
		margin-top: 9px;
	}

	#specialties-module.specialty-node .floated-image {
		display: block;
		float: none;
		margin-left: 0px;
	}
}

@media (max-width: 479px) {
	#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-left h2 {
		white-space: normal;
	}

	#physicians-module.node .md-patient-rating-card {
		max-width: 100%;
		min-height: auto;
		width: 100%;
	}

	#physicians-module.node .md-patient-rating-card-score-wrap {
		display: flex;
	}

	#physicians-module.advanced-search .physicians-advanced-search-page-header .physicians-advanced-search-header-split.split-right .button.physicians-view-all {
		width: 100%;
		max-width: 100%;
	}

	#specialties-module.specialty-node h2 {
		font-size: 1.4125em;
	}

	#specialties-module.specialties-collection.resources-collection .md-resources-icon-title-row {
		display: block;
	}

	#specialties-module.specialties-collection.resources-collection .md-column {
		float: none;
	}

	#specialties-module.specialties-collection.resources-collection .md-resources-item-summary {
		width: 100%;
		padding-top: 0px;
	}

	#specialties-module.resources-collection .md-resources-link-title.flex-link-title {
		margin-top: 1em;
	}

	#specialties-module.resources-collection .md-column._10.file-type.flex-file-type {
		text-align: left;
	}

	#physicians-module.node .md-patient-comments-show-more-wrap {
		-moz-box-direction: normal;
		-moz-box-orient: vertical;
		flex-direction: column;
		justify-content: space-around;
	}

	#physicians-module.node .button.md-show-more-comments {
		-moz-box-ordinal-group: 2;
		order: 1;
	}

	.md-list.services-list {
		-moz-column-count: 1;
	}
}


/* GDPR */
#cookie-consent-container:not(.minimized) {
	padding: 1rem;
	display: flex;
	justify-content: center;
}

#cookie-consent-container .consent-disclaimer {
	width: calc(100% - 400px);
	padding-right: 1rem;
	padding-left: 1rem;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1.3rem;
	max-width: 1200px;
}

#cookie-consent-container .consent-actions {
	width: 400px;
	order: 2;
}

#cookie-consent-form-container form {
	top: 8rem !important;
}

#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	min-width: auto;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
}

#cookie-consent-form-container form .consent-header {
	text-align: center;
}

@media screen and (max-width: 1200px) {
	#cookie-consent-container .consent-actions .consent-actions-container {
		align-items: center;
		flex-direction: row;
	}
}

@media (max-width: 991px) {
	#cookie-consent-container .consent-disclaimer {
		width: 100%;
		max-width: none;
	}

	#cookie-consent-container .consent-actions .consent-dismiss-trigger-container {
		text-align: right;
		position: absolute;
		right: 0;
		top: 7px;
	}

	#cookie-consent-container .consent-actions .consent-dismiss-trigger-container {
		right: 15px;
	}
}

@media (max-width: 767px) {
	#cookie-consent-container:not(.minimized) {
		flex-wrap: wrap;
	}

	#cookie-consent-container .consent-actions .consent-actions-container {
		align-items: center;
	}

	#cookie-consent-container .consent-actions a {
		margin-top: 0px;
	}
}

@media (max-width: 479px) {
	#cookie-consent-container .consent-disclaimer {
		padding-right: 0px;
		font-size: 0.612rem;
		line-height: 1.075rem;
		padding-top: 10px;
	}

	#cookie-consent-container .consent-actions {
		width: 250px;
	}
}
